import { BaseIdentity } from '../domain-models/base-identity';
import { OrchestratorViewModel } from './orchestrator-view-model';
import { MasterViewModel } from './master-view-model';
import { ViewModelFactory } from './view-model-factory';
import { ReplaySubject, Observable } from 'rxjs';
import { MasterDetailOrchestratorViewModelInterface } from './master-detail-orchestrator-view-model.interface';
import { filter, map, takeUntil, take, skip, takeWhile, switchMap } from 'rxjs/operators';
import { CrudApiClient } from '../api-clients/crud/crud-api-client';
import { CoreModel } from '../domain-models/core-model';
import { RootModelTypeInspector } from '../api-clients/decorators/root-model-type.decorator';
import { MasterDetailRootViewModel } from './master-detail-root-view-model';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { AccessMode } from '../meta-data/access-mode.enum';
import { AutoCompleteModelOptions, OrderBy } from '../domain-models/autocomplete/auto-complete-options';
import { ColumnInfoCollection } from './column-info-collection';
import { ColumnInfo } from './column-info';
import { ClassInformationType } from '@nts/std/utility';
import { ZoomConfigurationDto } from '../domain-models/zoom/dto/zoom-configuration.dto';
import { ZoomResult } from '../domain-models';
import { plainToClass } from "@nts/std/serialization";
import { ExtColumnInfo } from './ext-column-info';

export abstract class MasterDetailOrchestratorViewModel<
    TViewModel extends MasterDetailRootViewModel<TModel, TIdentity>,
    TApiClient extends CrudApiClient<TModel, TIdentity>,
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends OrchestratorViewModel<TViewModel, TApiClient, TModel, TIdentity>
    implements MasterDetailOrchestratorViewModelInterface {

    override classType = ClassInformationType.MasterDetailOrchestratorViewModel;
    masterViewModelChanged: ReplaySubject<void> = new ReplaySubject();

    get masterViewModel(): MasterViewModel<TViewModel, TModel, TIdentity> {
        return this._masterViewModel;
    }
    getZoomResultSessionStorageKey() {
        return `${this.metadata.rootFullName}_ZoomResult`;
    }

    getZoomConfigurationSessionStorageKey() {
        return `${this.metadata.rootFullName}_ZoomConfiguration`;
    }

    private _masterViewModel: MasterViewModel<TViewModel, TModel, TIdentity>;
    private _searchPropertyNames: string[] = null;
    private currentRemovingIdentity: TIdentity;
    private initialRootViewModel;

    override async initialize(): Promise<void> {
        await super.initialize();
        const domainModelClass = RootModelTypeInspector.getValue(this.apiClient);
        this.mockedRootDomainModel = this.createMockDomainModel<TModel>(domainModelClass, this.metadata);
        if (this.mockedRootDomainModel != null) {
            const viewModel = await ViewModelFactory.createMasterViewModel<
                TViewModel, MasterViewModel<TViewModel, TModel, TIdentity>, TModel, TIdentity>(
                    MasterViewModel, this.mockedRootDomainModel, this.metadata, this, this.apiClient.rootModelType);
            this.setMasterViewModel(viewModel);
        }

        this.masterViewModel.selectionChanging.pipe(takeUntil(this.onDestroy$)).subscribe(async (model: TModel) => {
            // Se è selezionato lo stesso item precedente non faccio niente
            if (this.masterViewModel.searchResult.selection?.length === 1) {
                if (this.rootViewModel?.domainModel?.currentIdentity?.equals(this.masterViewModel.searchResult.selection[0].domainModel.currentIdentity)) {
                    return;
                }
            }
            const result = await this.confirmLosingUnsavedDataAsync();
            if (result) {
                // Confermo le modifiche e seleziono il nuovo item
                if (this.rootViewModel?.domainModel?.currentIdentity == null || !model.currentIdentity.equals(this.rootViewModel?.domainModel?.currentIdentity)) {
                    if (this.actionInProgress == false) {
                        this.eventDispatcher.onActionInProgress.next(true);
                    }
                    await this.getByIdentity(model.currentIdentity);
                    this.eventDispatcher.onActionInProgress.next(false);
    
                    this.masterViewModel.setSelectedItem(this.rootViewModel);

                    this.storeExecuted.pipe(takeUntil(this.getByIdentityExecuted)).subscribe(() => {
                        this.masterViewModel.updateSelectedItem(this.rootViewModel);
                    })
                }
            } else {
                // Se non voglio perdere le modifiche riseleziono l'item precedente
                let foundResult = null
                if (this.masterViewModel.searchResult.length > 0 && this.rootViewModel != null){
                    foundResult = this.masterViewModel.searchResult.find((rootViewModel) => {
                        return this.rootViewModel.domainModel.currentIdentity.equals(rootViewModel.domainModel.currentIdentity)
                    })
                    if (foundResult){
                        this.masterViewModel.searchResult.selection = [this.rootViewModel];
                        this.masterViewModel.setSelectedItem(this.rootViewModel);
                        this.masterViewModel.updateSelectedItem(this.rootViewModel);
                    }
                }
            }
        });

        this.masterViewModel.selectionChanging.pipe(takeUntil(this.onDestroy$)).subscribe(async (model: TModel) => {

        })

        this.masterViewModelChanged.pipe(takeUntil(this.onDestroy$)).subscribe(async() => {
            this.masterViewModel.searchPropertyNames = this.getSearchPropertyNames();
        });

        this.rootViewModelChanged.pipe(takeUntil(this.onDestroy$), take(1)).subscribe(async() => {
            setTimeout(async ()=>{
                await this.loadZoomDataFromLocalStorage();
            })
        });

        this.zoomSelectedChanged.pipe(takeUntil(this.onDestroy$)).subscribe(async ()=>{
            await this.loadZoomDataFromLocalStorage();
        });

        this.masterViewModel.searchResult.datasourceUpdated.pipe(
            switchMap(() => this.masterViewModel.searchResult.paginatedCollectionItemsLoaded)
        ).subscribe(() => {
            let foundResult = null
            if (this.masterViewModel.searchResult.length > 0 && this.rootViewModel != null){
                 foundResult = this.masterViewModel.searchResult.find((rootViewModel) => {
                    return this.rootViewModel.domainModel.currentIdentity.equals(rootViewModel.domainModel.currentIdentity)
                })
                if (foundResult){

                    if (this.masterViewModel.searchResult.selection?.length > 0) {
                        if (this.masterViewModel.searchResult.selection.find((s => s === foundResult))){
                            // Se è già selezionato lo stesso elemento non lo riseleziono
                            return
                        }
                    }

                    setTimeout(async ()=>{
                        this.masterViewModel.searchResult.selection = [foundResult];
                    });
                }
            }
        })

        this.eventDispatcher.onNavigationPanelCollapsed.next(false);
        this.eventDispatcher.onNavigationPanelVisibilityChanged$.next(true);
    }

    private _autoCompleteFilter: AutoCompleteModelOptions;

    getAutoCompleteModelOptions(): AutoCompleteModelOptions {
        if (!this._autoCompleteFilter) {
            this._autoCompleteFilter = new AutoCompleteModelOptions();
        }
        return this._autoCompleteFilter;
    }

    /**
     * Se sono presenti informazioni nel local storage dello zoom, non sono in creazione e l'identity della rotta corrisponde avvio in automatico la lista di navigazione con i parametri dello zoom,
     * se trovo l'identity nella lista di navigazione la seleziono
     * @returns 
     */
    async loadZoomDataFromLocalStorage(): Promise<void>{
        const zoomResultPlain = window.sessionStorage.getItem(this.getZoomResultSessionStorageKey());
        const zoomConfigurationPlain = window.sessionStorage.getItem(this.getZoomConfigurationSessionStorageKey());        
        if (zoomResultPlain && zoomConfigurationPlain && this.jsonRouteParam?.length > 0 && this.eventDispatcher.onNavigationPanelVisibilityChanged$.value === true){

            const zoomResult = plainToClass(ZoomResult, JSON.parse(zoomResultPlain), { strategy: 'excludeAll' });

            const identityFromRoute = JSON.parse(this.jsonRouteParam);
            const identityFromZoomResult = JSON.parse(zoomResult.result);

            let loadZoomData = true;

            for (const property in identityFromZoomResult) {
                if (identityFromRoute[property] != null ) {
                    if (identityFromRoute[property] !== identityFromZoomResult[property]) {
                        loadZoomData = false;
                        break;
                    }
                } else {
                    loadZoomData = false;
                    break;
                }
            }

            if(!loadZoomData) {
                return;
            }

            // const identity = plainToClass<TIdentity, TIdentity>(
            //     this.identityType as ClassConstructor<TIdentity>, JSON.parse(jsonIdentity) as TIdentity);

            const zoomConfiguration = plainToClass(ZoomConfigurationDto, JSON.parse(zoomConfigurationPlain), { strategy: 'excludeAll' });

            const options = new AutoCompleteModelOptions();
            options.additionalFilters = zoomConfiguration.findValuesOptions.filters;
            options.orderByPropertyNames = zoomConfiguration.findValuesOptions.orderByPropertyNames;
            await this.masterViewModel.execSearch('', true, options);

            let foundResult = null;

            this.masterViewModel.searchResult.paginatedCollectionItemsLoaded.pipe(
                takeUntil(this.masterViewModel.searchResult.datasourceUpdated), 
                takeUntil(this.masterViewModel.selectionChanged),
                takeWhile(() => foundResult == null)
            ).subscribe(x=>{
                if (this.masterViewModel.searchResult.length > 0 && zoomResult?.result?.length > 0){
                    foundResult = this.masterViewModel.searchResult.find((rootViewModel) => {
                        const zoomResultObj = JSON.parse(zoomResult.result);
                        return rootViewModel.domainModelMetaData.identityNames.every((identityName: string) => {
                            const propertyName = MetaDataUtils.toCamelCase(identityName);
                            const zoomResultProperty = zoomResultObj[propertyName]
                            return zoomResultProperty === rootViewModel.getProperty(propertyName).value;
                        });
                    })
                    if (foundResult){

                        if (this.masterViewModel.searchResult.selection?.length > 0) {
                            if (this.masterViewModel.searchResult.selection.find((s => s === foundResult))){
                                // Se è già selezionato lo stesso elemento non lo riseleziono
                                return
                            }
                        }

                        setTimeout(async ()=>{
                            this.masterViewModel.searchResult.selection = [foundResult];
                        });
                    }
                }
            })
        }
    }

    override notifyModified() {
        super.notifyModified();
    }

    override async restore() {
        await super.restore();
        if (this.rootViewModel?.domainModel) {
            this.masterViewModel.updateSelectedItem(this.rootViewModel);
        }
    }

    override async preRemove(identity: TIdentity) {
        this.currentRemovingIdentity = identity;
    }

    override async postRemove() {
        this.masterViewModel.removeItem(this.currentRemovingIdentity);
        this.masterViewModel.searchResult.selection = [];
    }

    override async postCreate() {
        this.masterViewModel.searchResult.selection = [];        
    }
    
    override async toggleLeftSideBar() {
        this.eventDispatcher.onNavigationPanelCollapsed.next(!this.navigationPanelCollapsed);
    }

    // Can
    override canToggleLeftSideBar(): Observable<boolean> {
        return this.eventDispatcher.onMetaDataLoaded.pipe(filter((isLoaded) => isLoaded), map(() => 
            !(this.metadata.rootMetaData.userMetaData?.securityAccess === AccessMode.Deny)
        ))
    }

    // Is visible
    override isVisibleToggleLeftSideBar(): Observable<boolean> {
        return this.eventDispatcher.onMetaDataLoaded.pipe(filter((isLoaded) => isLoaded), map(() => 
            !(this.metadata.rootMetaData.userMetaData?.securityAccess === AccessMode.Deny)
        ))
    }

    // Is highlighted
    override isHighlightedToggleLeftSideBar(x: any): Observable<boolean> {
        return this.eventDispatcher.onMasterAreaSelected.pipe(map((isSelected) => {
            return isSelected;
        }));
    }

    /**
     * 
     * @param columns lista delle colonne da usare nella lista di navigazione
     * @returns 
     */
    getSearchResultColumnList(columns: string[]): string[] {
        return columns;
    }

    setCustomAutoCompleteFilterForPath(path: string, value: any, options: AutoCompleteModelOptions): boolean {
        // Per poter modificare i filtri:
        // nel caso di filtro standard return false
        // nel caso di filtro custom return true
        return false;
    }

    getMainProperties(): string[] {
        const mains = [];
        if (this.metadata.rootMetaData.getMainDescriptionProperty()?.name?.length > 0) {
            mains.push(this.metadata.rootMetaData.getMainDescriptionProperty()?.name);
        }
        return mains;
    }

    /**
     * Viene utilizzata per inizializzare le custom grid column per la ricerca
     * @param customGridColumns 
     */
    async initMasterAreaCustomGridColumns(customGridColumns: Map<string, ColumnInfoCollection>) {
    }

    /**
     * Ritorna una colonna custom basata sulle property custom definite nel rootviewodel
     *
     * @param rootPropertyViewModelName
     * @param header
     * @param headerTooltip
     * @returns Ritorna la ColumnInfo
     */
    getMasterAreaCustomColumnGridColumn(
        rootPropertyViewModelName: string,
        header: string,
        headerTooltip: string
    ): ColumnInfo {
        const column = new ColumnInfo(rootPropertyViewModelName);
        column.header = header;
        column.headerTooltip = headerTooltip;
        return column;
    }

    applyVisibilityLogicToColumn(c: ColumnInfo | ExtColumnInfo, isFullscreen: boolean) {

        if (isFullscreen) {
            c.isVisible = true;
        } else {
            // Recupero i meta dati
            const metaData = this.metadata.rootMetaData.getPropertyMetaData(c.propertyName);

            // Se è un campo guid lo rendo invisibile
            if (metaData?.getType() === 'Guid') {
                c.isVisible = false;
            } else if (metaData) {
                const mainProperties = this.getMainProperties();
                const identityPropertyNames = this.metadata.rootMetaData.identityNames.map((i) => MetaDataUtils.toCamelCase(i));
                if (mainProperties?.length > 0 && mainProperties.find((m) => MetaDataUtils.toCamelCase(m) === c.propertyName) != null) {
                    c.isVisible = true;
                } else if (identityPropertyNames.length > 0 && identityPropertyNames.indexOf(c.propertyName) > -1) {
                    c.isVisible = true;
                } else {
                    c.isVisible = false;
                }
            } else {
                c.isVisible = false;
            }
        }        
    }

    applyCustomVisibilityLogicToColumn(c: ColumnInfo | ExtColumnInfo, isFullscreen: boolean) {

    }

    getCustomSearchColumn(): string[] {
        return [];
    }

    getSearchResultOrderList(currentOrderDefinition: string[]): string[] {
        return null;
    }

    getOrderByPropertyNames(): OrderBy[] {
        return null;
    }

    /**
     * Il testo inserito nel campo di ricerca viene cercato nei campi definiti in questa lista.
     * In automatico viene cercato nel codici e nei campi main vedi la funzione getMainProperties()
     * @returns lista dei campi su cui fare la ricerca
     */
    getSearchPropertyNames(): string[] {
        if (!this._searchPropertyNames) {
            const searchPropertyNames = [...this.metadata.rootMetaData.identityNames, ...this.getMainProperties()].filter((notNull) => notNull);
            // TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
            this._searchPropertyNames = this.applyEnterpriseBarrierLogicToSearchProperties(searchPropertyNames.map((p) => MetaDataUtils.toPascalCase(p)));
        }
        return (this._searchPropertyNames);
    }

    /**
     * Applica logiche di filtro del search property names in base all'enterprirse barrier 
     * 
     * @param searchPropertyNames lista delle property da filtrare in base alle logiche di enterprise barrier
     * @returns 
     */
    applyEnterpriseBarrierLogicToSearchProperties(searchPropertyNames: string[]): string[] {
        if (this.env.isEnterpriseBarrierRequired) {
            return searchPropertyNames.filter((s) => s !== 'CompanyId')
        } else {
            return searchPropertyNames;
        }
    }

    private setMasterViewModel(value: MasterViewModel<TViewModel, TModel, TIdentity>) {
        this._masterViewModel = value;
        this.masterViewModelChanged.next();
    }
}
