import { Subscription, merge, Subject, firstValueFrom } from 'rxjs';
import { debounceTime, take, delay, takeUntil, filter, switchMap, startWith } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ElementRef, Renderer2, HostBinding } from '@angular/core';
import { ExternalStringCellEditorComponent } from './cell_edit_components/external-string-cell-editor/external-string-cell-editor.component';
import { CommandCellRendererComponent } from './cell_render_components/command_cell_renderer.component';
import { ExternalCellRendererComponent } from './cell_render_components/external-cell-renderer/external-cell-renderer.component';
import { GridApi, GridOptions, ColDef, CellKeyDownEvent, FullWidthCellKeyDownEvent, CellClassParams, GetLocaleTextParams, GridReadyEvent, RowValueChangedEvent, SuppressKeyboardEventParams, ColumnResizedEvent, PaginationChangedEvent, ITextFilterParams } from '@ag-grid-community/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExternalListCellEditorComponent } from './cell_edit_components/external-list-cell-editor/external-list-cell-editor.component';
import moment from 'moment';
import { AgGridModule } from '@ag-grid-community/angular';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { DynamicCellRendererComponent } from './cell_render_components/dynamic-cell-renderer/dynamic-cell-renderer.component';
import { DynamicCellEditorComponent } from './cell_edit_components/dynamic-cell-editor/dynamic-cell-editor.component';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from "@ag-grid-community/csv-export";

// External Deps
import { CollectionChangedAction, CollectionChangedEventArgs } from '../../../src/lib/view-models/collection-changed-event-args';
import { CollectionViewModelInterface } from '../../../src/lib/view-models/collection-view-model.interface';
import { ColumnInfoCollection } from '../../../src/lib/view-models/column-info-collection';
import { CoreOrchestratorViewModelInterface } from '../../../src/lib/view-models/core-orchestrator-view-model.interface';
import { ColumnInfo } from '../../../src/lib/view-models/column-info';
import { ExtColumnInfo } from '../../../src/lib/view-models/ext-column-info';
import { ViewModelInterface } from '../../../src/lib/view-models/view-model.interface';
import { DatasourceCollectionViewModelInterface } from '../../../src/lib/view-models/datasource-collection-view-model.interface';
import { MessageResourceManager } from '../../../src/lib/resources/message-resource-manager';
import { PropertyViewModelInterface } from '../../../src/lib/view-models/property-view-model.interface';
import { BaseRowNumberCollectionViewModel } from '../../../src/lib/view-models/base-row-number-collection-view-model';
import { MetaDataUtils } from '../../../src/lib/meta-data/meta-data-utils';
import { GridColumnsMetaData } from '../../../src/lib/layout-meta-data/grid-columns-meta-data';
import { ExternalFieldInputMode, ExternalFieldMetaData, ExternalLayoutMetaData, FieldTypes, GridFieldMetaData, UserLayoutColumnMetaData } from '../../../src/lib/layout-meta-data';
import { GridFieldComponent } from '../../../src/lib/components/controls/core/grid-field/grid-field.component';
import { GridUserLayoutDataDto } from '../../../src/lib/domain-models/layout/grid-user-layout-data.dto';
import { ColumnsProviderInterface } from '../../../src/lib/view-models/columns_provider.interface';
import { ColumnsUtils } from '../../../src/lib/view-models/columns_utils';
import { AccessMode } from '../../../src/lib/meta-data/access-mode.enum';
import { DateTimeOffset } from '../../../src/lib/domain-models/date-time-offset';
import { DateTime } from '../../../src/lib/domain-models/date-time';
import { BaseNumericPropertyViewModel } from '../../../src/lib/view-models/base-type/base-numeric-property-view-model';
import { BaseDateTimePropertyViewModel } from '../../../src/lib/view-models/base-type/date-time-property-view-model';
import { BaseDateTimeOffsetPropertyViewModel } from '../../../src/lib/view-models/base-type/date-time-offset-property-view-model';
import { RibbonButtonComponent } from '../../../src/lib/components/shared/buttons/ribbon-button/ribbon-button.component';
import { BaseEnumPropertyViewModel } from '../../../src/lib/view-models/base-type/enum-property-view-model';
import { ExternalViewModelInterface } from '../../../src/lib/view-models/external-view-model.interface';
import { NNumericPropertyViewModel } from '../../../src/lib/view-models/base-type/nnumeric-property-view-model';
import { ExternalViewModel } from '../../../src/lib/view-models/external-view-model';
import { BaseTextBoxComponent } from "../../../src/lib/components/controls/core/base/base-text-box/base-text-box.component";
import { RibbonTextBoxComponent } from '../../../src/lib/components/controls/ribbon-text-box/ribbon-text-box.component';
import { CustomHeaderComponent } from './custom-header/custom-header.component';

ModuleRegistry.registerModules([InfiniteRowModelModule, ClientSideRowModelModule, CsvExportModule]);

const KEY_SPACE = ' ';
const KEY_DELETE = 'Delete';
const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';

@UntilDestroy()
@Component({
    selector: 'nts-grid',
    templateUrl: './grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./grid.component.scss'],
    standalone: true,
    imports: [
      AgGridModule,
      SvgIconComponent,
      NgIf,
      NgxPopperjsModule,
      RibbonButtonComponent,
      AsyncPipe,
      NgFor,
      BaseTextBoxComponent,
      RibbonTextBoxComponent
]
})
export class GridComponent extends GridFieldComponent implements OnInit, OnChanges, AfterViewInit {

    @HostBinding('class.additional-field')
    @Input() override additionalField = false;

    @HostBinding('class.full-column')
    @Input() fullColumn = false;

    /**
     * Property calcolata in base alle due property: isHidden e collectionViewModel.isVisible
     */
    @HostBinding('class.is-hidden')
    isHiddenCalculated = false;

    /**
     * Property valorizzata solo dal layout custom, vincerà sul collectionViewModel.isVisible solo se sarà impostata a false
     */
    @Input() override isHidden = false;

    @Input() collectionViewModel: CollectionViewModelInterface<ViewModelInterface>;
    @Input() isDisabled: boolean;
    @Input() areAllCommandsDisabled = false;
    @Input() showErrorsInCells = true;
    @Input() columns: ColumnInfoCollection;
    @Input() title = '';
    @Input() pagination: any;
    @Input() dynamicHeight = false;
    @Input() autoCreateRow = false;
    @Input() disableAllCommands = null;
    @Input() defaultCommandsName = null;
    @Input() customCommandsName = [];
    @Input() customViewCommandsName = [];
    @Input() showToolbar: boolean;
    @Input() minRowNumberHeight: number = 10;
    @Input() defaultViewCommandsName = ['focusGridCommand', 'exitFocusGridCommand', 'searchGridFilterCommand', 'gridFiltersCommand', 'exportCsvCommand', 'openGridSettingsCommand'];
    @Input() tooltipAppendTo = "body";

    /**
     * @deprecated utilizzare il parametro isDisabled
     */
    @Input() simpleRenderer = false;

    @Output() rowDoubleClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onGridOptionsReady: EventEmitter<{ gridOptions: GridOptions, api: GridApi }> = new EventEmitter<{ gridOptions: GridOptions, api: GridApi }>();
    @Output() collectionViewModelInitialized = new EventEmitter<void>();
    @Output() override onColumnsChanged: EventEmitter<GridUserLayoutDataDto> = new EventEmitter();

    gridOptions: GridOptions;
    columnDefinitions: ColDef[];
    activeExternalSubscription: Subscription;
    components;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    overlayText: string | null;
    api!: GridApi;

    get name(): string {
        return MetaDataUtils.toCamelCase(this.collectionViewModel.reservedName);
    }

    get columnDefinition(): GridColumnsMetaData {

        if (this._columnDefinition) {
            return this._columnDefinition;
        }

        const orchestratorViewModel = this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface;
        const dependentMetaData = this.collectionViewModel.collectionMetaData.dependentMetaData;
        const useMessageResourceKey = orchestratorViewModel.rootViewModel.aggregateMetaData.useMessageResourceKey;

        const metaData = new GridColumnsMetaData();
        metaData.modelName = dependentMetaData.name;
        metaData.gridFields = dependentMetaData.propertyNames.map((pn) => {

            // TODO nascondere i backing field degli external

            if (pn === 'CurrentState') {
                return null;
            }
            const pm = dependentMetaData.getPropertyMetaData(pn);
            const simple = new GridFieldMetaData();
            simple.isAdditionalField = false;
            simple.descriptions.description = useMessageResourceKey ? MessageResourceManager.Current.getMessage(pm.descriptions.descriptionKey) : pm.descriptions.description;
            simple.descriptions.displayName = useMessageResourceKey ? MessageResourceManager.Current.getMessage(pm.descriptions.displayNameKey) : pm.descriptions.displayName;
            simple.isFullColumn = false;
            // ricerca nel template originale se è visibile
            simple.name = MetaDataUtils.toCamelCase(pm.name);

            const currentColumnDefinedInTemplate = this.columns.find((c) => c.fieldName === simple.name + '.value');
            simple.isVisible = currentColumnDefinedInTemplate ? currentColumnDefinedInTemplate.isVisible : false;
            simple.isDisabled = currentColumnDefinedInTemplate ? !currentColumnDefinedInTemplate.isEnabled : false;
            simple.path = this.path?.length > 0 ? this.path + '.' + this.name + ".selectedItem" : this.name + ".selectedItem";
            return simple;
        }).filter((el) =>
            el != null && el.isVisible
        );

        // TODO aggiungere 1-1

        // Costruisco la lista degli external
        metaData.externals = dependentMetaData.externals.map((external) => {
            const ext = new ExternalLayoutMetaData();
            ext.isFullColumn = false;
            ext.isAdditionalField = false;
            ext.fieldType = FieldTypes.External;
            ext.isDisabled = false;
            ext.isVisible = true;
            ext.name = MetaDataUtils.toCamelCase(external.principalPropertyName);
            ext.path = this.path?.length > 0 ? this.path + '.' + this.name + ".selectedItem" : this.name + ".selectedItem";
            ext.fullPathName = ext.path + '.' + ext.name
            ext.showCodeInDescription = true;
            ext.externalFields = external.dependentAggregateMetaData.rootMetaData.identityNames.map((identity) => {
                const externalFieldCode = new ExternalFieldMetaData();
                externalFieldCode.name = MetaDataUtils.toCamelCase(identity);

                // ricerco tra le column definition se è stata impostato una definizione autocomplete custom
                const foundColumnDefinition = this.columns.find((c) => c.fieldName === ext.name + '.' + externalFieldCode.name + '.value')
                if (foundColumnDefinition) {
                    externalFieldCode.displayName = foundColumnDefinition.header;
                    externalFieldCode.inputMode = (foundColumnDefinition as ExtColumnInfo).inputMode;
                } else {
                    const ovm = this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface;
                    const useMessageResourceKey = ovm.useMessageResourceKey;
                    const pvm = external.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(externalFieldCode.name);
                    externalFieldCode.displayName = useMessageResourceKey ? MessageResourceManager.Current.getMessage(pvm.descriptions.displayNameKey) : pvm.descriptions.displayName;
                    externalFieldCode.inputMode = ExternalFieldInputMode.Autocomplete;
                }
                return externalFieldCode;
            })
            ext.availableExternalCodes = external.dependentAggregateMetaData.rootMetaData.identityNames.map((i) => MetaDataUtils.toCamelCase(i));
            ext.availableExternalDecodes = external.dependentAggregateMetaData.rootMetaData.strings.map((s) => MetaDataUtils.toCamelCase(s.name)).filter((s) => ext.availableExternalCodes.indexOf(s) === -1);
            ext.descriptions.description = useMessageResourceKey ? MessageResourceManager.Current.getMessage(external.descriptions.descriptionKey) : external.descriptions.description;
            ext.descriptions.displayName = useMessageResourceKey ? MessageResourceManager.Current.getMessage(external.descriptions.displayNameKey) : external.descriptions.displayName;
            return ext;
        })

        // external
        const externalGridFields: GridFieldMetaData[] = this.columns.filter((c) => c.isExternal && c.isVisible).map((c) => {
            const relatedExternal = metaData.externals.find((e) => e.name === c.path);
            const relatedExternalMetaData = dependentMetaData.externals.find((e) => MetaDataUtils.toCamelCase(e.principalPropertyName) === c.path);

            if (!relatedExternal || !relatedExternalMetaData) {
                return null
            }

            const externalField = new GridFieldMetaData();
            externalField.isAdditionalField = false;
            externalField.isAutoSize = true
            externalField.isDisabled = !c.isEnabled;
            externalField.isFullColumn = false;
            externalField.isVisible = true;
            // externalField.position
            externalField.name = c.propertyName;
            externalField.path = (this.path?.length > 0 ? this.path + '.' + this.name + ".selectedItem" : this.name + ".selectedItem") + '.' + c.path;
            // externalField.fullPathName
            externalField.isExternalCode = relatedExternalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.map((i) => MetaDataUtils.toCamelCase(i)).indexOf(externalField.name) > -1;
            externalField.externalRef = relatedExternal;

            // TODO se ci sono più codici deve gestire correttamente le descrizioni

            if (externalField.isExternalCode) {
                externalField.descriptions.description = useMessageResourceKey ?
                    MessageResourceManager.Current.getMessage(relatedExternalMetaData.descriptions.descriptionKey) :
                    relatedExternalMetaData.descriptions.description;
                externalField.descriptions.displayName = useMessageResourceKey ?
                    MessageResourceManager.Current.getMessage(relatedExternalMetaData.descriptions.displayNameKey) :
                    relatedExternalMetaData.descriptions.displayName;
            } else if((c as ExtColumnInfo).isRef) {
                const propertyMetaData = relatedExternalMetaData.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(externalField.name);
                externalField.descriptions.description = useMessageResourceKey ?
                    MessageResourceManager.Current.getMessage(relatedExternalMetaData.descriptions.descriptionKey) :
                    relatedExternalMetaData.descriptions.descriptionKey
                externalField.descriptions.displayName = useMessageResourceKey ?
                    MessageResourceManager.Current.getMessage(relatedExternalMetaData.descriptions.displayNameKey) :
                    relatedExternalMetaData.descriptions.displayName;
            } else {
                const propertyMetaData = relatedExternalMetaData.dependentAggregateMetaData.rootMetaData.getPropertyMetaData(externalField.name);
                externalField.descriptions.description = useMessageResourceKey ?
                    MessageResourceManager.Current.getMessage(relatedExternalMetaData.descriptions.descriptionKey) + '->' +
                    MessageResourceManager.Current.getMessage(propertyMetaData.descriptions.descriptionKey) :
                    relatedExternalMetaData.descriptions.descriptionKey + '->' +
                    propertyMetaData.descriptions.description;
                externalField.descriptions.displayName = useMessageResourceKey ?
                    MessageResourceManager.Current.getMessage(relatedExternalMetaData.descriptions.displayNameKey) + '->' +
                    MessageResourceManager.Current.getMessage(propertyMetaData.descriptions.displayNameKey) :
                    relatedExternalMetaData.descriptions.displayName + '->' +
                    propertyMetaData.descriptions.displayName;
            }

            //     externalField.isFullColumn = false;
            //     externalField.name = MetaDataUtils.toCamelCase(external.principalPropertyName);
            //     externalField.path = this.path?.length > 0 ? this.path + '.' + this.name + ".selectedItem" : this.name + ".selectedItem";
            //     externalField.availableExternalCodes = external.dependentAggregateMetaData.rootMetaData.identityNames.map((i) => MetaDataUtils.toCamelCase(i));
            //     externalField.showCode = false;

            //     // recupero la lista deglie external decode disponibili rimuovendo i codici
            //     externalField.availableExternalDecodes = external.dependentAggregateMetaData.rootMetaData.strings.map((s) => MetaDataUtils.toCamelCase(s.name)).filter((s) => externalField.availableExternalCodes.indexOf(s) === -1);

            //     // Verifico se sono state definite le external nel template
            //     const currentExternalColumnsDefinedInTemplate = this.columns.filter((c) => c.fieldName.startsWith(externalField.name + '.'));

            //     // Se sono state definite le recupero
            //     if (currentExternalColumnsDefinedInTemplate.length > 0) {

            //         const currentExternalCodeColumnsDefinedInTemplate = currentExternalColumnsDefinedInTemplate.filter((c) => externalField.availableExternalCodes.indexOf(MetaDataUtils.toCamelCase(c.propertyName)) > -1);
            //         externalField.externalFields = currentExternalCodeColumnsDefinedInTemplate.map((c) => {
            //             const externalFieldCode = new ExternalFieldMetaData();
            //             externalFieldCode.name = MetaDataUtils.toCamelCase(c.propertyName);
            //             externalFieldCode.isAutocomplete = true;
            //             return externalFieldCode;
            //         })

            //         const currentExternalDecodeColumnsDefinedInTemplate = currentExternalColumnsDefinedInTemplate.filter((c) => externalField.availableExternalDecodes.indexOf(MetaDataUtils.toCamelCase(c.propertyName)) > -1);
            //         externalField.externalDecodes = currentExternalDecodeColumnsDefinedInTemplate.map((c) => MetaDataUtils.toCamelCase(c.propertyName));
            //         externalField.isVisible = true;
            //     } else {

            //         // imposto il primo codice di default con autocomplete
            //         const externalFieldCode = new ExternalFieldMetaData();
            //         externalFieldCode.isAutocomplete = true;
            //         externalFieldCode.name = externalField.availableExternalCodes[0];
            //         externalField.externalFields = [externalFieldCode];

            //         // recupero la main description altrimento imposto il primo decode disponibile
            //         const decodeMainDescription = external.dependentAggregateMetaData.rootMetaData.strings.find(s => s.isMainDescription)?.name;
            //         externalField.externalDecodes = [MetaDataUtils.toCamelCase(decodeMainDescription) ?? externalField.availableExternalDecodes[0]];
            //         externalField.isVisible = false;
            //     }
            return externalField;
        }).filter((el) => {
            return el != null;
        });

        if (externalGridFields?.length > 0) {
            metaData.gridFields = [...metaData.gridFields, ...externalGridFields]
        }

        // Aggiunge anche i custom
        const columnProvider: ColumnsProviderInterface = this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface;
        const customColumns = columnProvider.customGridColumns.get(this.collectionViewModel.collectionMetaData.dependentMetaData.name);
        if (customColumns?.length > 0) {

            // TODO per adesso tutte le customColumns vengono considerate come campi simple
            const customColumnDefinition = customColumns.map((c) => {
                const simple = new GridFieldMetaData();
                simple.isAdditionalField = false;
                simple.descriptions.description = c.headerTooltip;
                simple.descriptions.displayName = c.header;
                simple.isFullColumn = false;
                simple.name = c.propertyName;
                const currentColumnDefinedInTemplate = this.columns.find((c) => c.fieldName === simple.name + '.value');
                simple.isVisible = currentColumnDefinedInTemplate ? currentColumnDefinedInTemplate.isVisible : false;
                simple.path = this.path?.length > 0 ? this.path + '.' + this.name + ".selectedItem" : this.name + ".selectedItem";
                return simple;
            })
            metaData.gridFields.push(...customColumnDefinition);
        }

        this._columnDefinition = metaData;
        return metaData;
    }

    get path(): string {
        return this.collectionViewModel.reservedPath;
    }

    get description(): string {
        const description = this.collectionViewModel.collectionMetaData.descriptions.description?.length > 0 ? this.collectionViewModel.collectionMetaData.descriptions.description : MessageResourceManager.Current.getMessage(this.collectionViewModel.collectionMetaData.descriptions.descriptionKey);
        if (description?.length === 0) {
            return this.title;
        }
        return this.title;
    }

    get displayName(): string {
        if (this.title?.length > 0) {
            return this.title;
        }
        return this.collectionViewModel.collectionMetaData.descriptions.displayName?.length > 0 ? this.collectionViewModel.collectionMetaData.descriptions.displayName : MessageResourceManager.Current.getMessage(this.collectionViewModel.collectionMetaData.descriptions.displayNameKey);
    }

    get securityTooltipDescription(): string {
        if (this.isSecurityAccessReadOnly()) {
            return MessageResourceManager.Current.getMessage('std_Security_Access_Mode_ReadOnly');
        }
        if (this.isSecurityAccessDeny()) {
            return MessageResourceManager.Current.getMessage('std_Security_Access_Mode_Deny');
        }
        return '';
    }

    get securityTooltipClass(): string {
        if (this.isSecurityAccessReadOnly()) {
            return 'alert';
        }
        if (this.isSecurityAccessDeny()) {
            return 'error';
        }
        return '';
    }

    private columnsWidthState = {};
    private readonly ROW_HEIGHT = 34;
    private gridSizeChanged$: EventEmitter<any> = new EventEmitter<any>();
    private publishColumnChangesDebouncer = new Subject<null | ColDef[]>();
    private destroySubscriptions = new Subject<void>();
    private _currentHeight = null;
    private _columnDefinition: GridColumnsMetaData;

    visibleRowsChange = new Subject<any>();

    constructor(
        private readonly hostElement: ElementRef,
        private readonly cd: ChangeDetectorRef,
        private readonly renderer: Renderer2
    ) {
        super();
        this.gridOptions = {} as GridOptions;
        this.gridOptions.rowSelection = 'single';

        // TODO implementare con l'id del property view model
        // this.gridOptions.getRowNodeId = this.pagination ? 10 : this.gridOptions.getRowNodeId;
        this.gridOptions.suppressRowTransform = true;
        this.gridOptions.suppressDragLeaveHidesColumns = true;

        // Traduzioni
        this.gridOptions.getLocaleText = (params: GetLocaleTextParams) => {
            const gridKey = 'std_AgGrid_' + params.key;
            const value = MessageResourceManager.Current.getMessage(gridKey);
            return value === gridKey ? params.defaultValue : value;
        };

        // TODO Tommy performance, provare batch-transactions per le collection
        // https://www.ag-grid.com/javascript-grid-data-update/#batch-transactions
        // flash per il record corrente che modifica una main description?
        this.gridOptions.defaultColDef = {
            sortable: true,
            resizable: true
        };

        this.gridOptions.onGridSizeChanged = (e) => {
            // this._gridApi.stopEditing();
            this.gridSizeChanged$.emit(e);

        };

        // Se si riattiva non si riesce a fare l'editing in una griglia con scrollbar orrizzontale
        // this.gridOptions.onBodyScroll = (e) => {
        //     // logica utile se si vuole riposizionare la cella corrente
        //     // https://github.com/ag-grid/ag-grid/issues/911
        //     this._gridApi.stopEditing();
        // };

        this.gridOptions.onRowDoubleClicked = (e) => {
            if (this.rowDoubleClick != null) {
                this.rowDoubleClick.emit(this.collectionViewModel.selectedItem);
                this.cd.detectChanges();
            }
        };

        this.gridOptions.onRowValueChanged = (event: RowValueChangedEvent<ViewModelInterface>) => {
            this.selectNodeFromDomainModelCollectionViewModelSelectedItem();
            this.setGrouping();
        };

        this.gridOptions.onGridReady = (event: GridReadyEvent) => {

            this.updateDynamicHeight();

            this.api = event.api;

            if (this.collectionViewModel instanceof BaseRowNumberCollectionViewModel) {

                this.api.applyColumnState({
                    state: [{ colId: 'rowNumber.value', sort: 'asc' }],
                    defaultState: { sort: null },
                });
            }

            this.selectNodeFromDomainModelCollectionViewModelSelectedItem();

            this.onGridOptionsReady.emit({ gridOptions: this.gridOptions, api: this.api })

            this.handleVisibleRowChanged();
        };

        this.showToolbar = true;

        // change selection with keys
        this.gridOptions.navigateToNextCell = (params) => {

            const previousCell = params.previousCellPosition;
            const suggestedNextCell = params.nextCellPosition;

            const fc = this.api.getFocusedCell();

            const KEY_UP = 'ArrowUp';
            const KEY_DOWN = 'ArrowDown';
            const KEY_LEFT = 'ArrowLeft';
            const KEY_RIGHT = 'ArrowRight';

            switch (params.key) {
                case KEY_DOWN:
                    // set selected cell on current cell + 1

                    this.api.forEachNode((node) => {
                        if (previousCell.rowIndex + 1 === node.rowIndex) {
                            node.setSelected(true);
                            // if (this._gridApi.getEditingCells().length > 0) {
                            //     this._gridApi.startEditingCell({
                            //         rowIndex: node.rowIndex,
                            //         colKey: fc.column
                            //     });
                            // }
                        }
                    });
                    if (this.autoCreateRow && !suggestedNextCell) {
                        this.collectionViewModel.selectedItem.validate();
                        if (!this.collectionViewModel.selectedItem.hasErrors) {
                            this.collectionViewModel.collectionChanged.pipe(untilDestroyed(this), take(1), delay(250)).subscribe(() => {
                                this.collectionViewModel.selection = [this.collectionViewModel[this.collectionViewModel.length - 1]];
                            });
                            this.collectionViewModel.addItemCommand.execute();
                        }
                    }
                    return suggestedNextCell;
                case KEY_UP:
                    // set selected cell on current cell - 1
                    this.api.forEachNode((node) => {
                        if (previousCell.rowIndex - 1 === node.rowIndex) {
                            node.setSelected(true);
                            // if (this._gridApi.getEditingCells().length > 0) {
                            //     this._gridApi.startEditingCell({
                            //         rowIndex: node.rowIndex,
                            //         colKey: fc.column
                            //     });
                            // }
                        }
                    });
                    return suggestedNextCell;
                case KEY_LEFT:
                case KEY_RIGHT:
                    return suggestedNextCell;
                default:
                    throw new Error('this will never happen, navigation is always on of the 4 keys above');
            }
        };
    }

    ngOnInit() {

        if (!this.columns) { throw new Error('Columns property is required for nts-grid!'); }

        // if (this.dynamicHeight) {
        //     this.gridOptions.domLayout = 'autoHeight';
        // }

        this.gridOptions.rowModelType = this.pagination ? 'infinite' : this.gridOptions.rowModelType;
        this.gridOptions.maxBlocksInCache = 0;
        this.gridOptions.cacheOverflowSize = this.pagination ? 0 : this.gridOptions.cacheOverflowSize;
        this.gridOptions.rowBuffer = this.pagination ? 0 : this.gridOptions.rowBuffer;
        this.gridOptions.maxConcurrentDatasourceRequests = this.pagination ? 1 : this.gridOptions.maxConcurrentDatasourceRequests;

        if (this.pagination) {
            this.gridOptions.onPaginationChanged = (event: PaginationChangedEvent) => {
                // I valori sono a base zero

                const dcvm = this.collectionViewModel as DatasourceCollectionViewModelInterface<any>;
                dcvm.paginationChanged.next(event);
                this.cd.detectChanges();
            };
        }

        this.gridOptions.onSelectionChanged = (e) => {
            if (this.collectionViewModel) {
                // this._gridApi.deselectAll();
                const selectedRows = this.api.getSelectedRows();

                const singleSelectedRow = selectedRows[0];
                const singleSelection = this.collectionViewModel.selection[0];
                const equals = singleSelection?.getDomainModel()?.currentIdentity?.equals(
                    singleSelectedRow?.getDomainModel()?.currentIdentity);

                if (equals !== true) {
                    this.collectionViewModel.selection = selectedRows;
                }
            }
        };

        this.gridOptions.onCellEditingStarted = (e) => {
            e.node.setSelected(true);
            if (this.collectionViewModel.externalRetriever != null) {
                (this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface).notifyPendingChangesStarting();
            }
        };

        this.gridOptions.onCellEditingStopped = (e) => {
            //     this.setGrouping();

            if (this.collectionViewModel.externalRetriever != null) {
                (this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface).notifyPendingChangesEnded();
            }
        };

        this.gridOptions.onDragStopped = () => {
            this.api.moveColumns(['rowHeader'], 0);
        };

        this.components = this.pagination ? {
            loadingRenderer: (params) => {
                if (params.data !== undefined) {
                    return params.value;
                } else {
                    return '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>';
                }
            },
        } : null;

        this.publishColumnChangesDebouncer.pipe(untilDestroyed(this), debounceTime(1000)).subscribe((columnDefinitions) => {
            if (this.collectionViewModel) {
                this.onColumnsChanged.emit(this.getCurrentGridUserLayoutDataDto(columnDefinitions));
            }
        })

        if (this.customCommandsName?.length === 0) {
            for (const [key, value] of this.collectionViewModel.commandList) {
                if (this.defaultCommandsName.indexOf(key) == -1) {
                    this.customCommandsName.push(key);
                }
            }
        }
    }

    ngAfterViewInit(): void {

        // TODO provare ad usare rowDataChanged e rimuovere negli altri punti
        // vedi https://www.ag-grid.com/javascript-grid-events/
        // this.gridOptions.api.addEventListener('componentStateChanged', () => {
        //     this.autoSizeColumns();
        // });

        this.updateDynamicHeight();

        this.gridSizeChanged$.pipe(untilDestroyed(this), debounceTime(250)).subscribe((e) => {
            // this.gridOptions.api.sizeColumnsToFit();
            // this.autoSizeColumns();
            // this.recalculateColumnsWidth();

            // Se si cambia l'altezza della griglia ricalcola la paginazione e resette la griglia
            if (Math.abs(this._currentHeight - e.clientHeight) > 5) {

                const blockSize = (Math.floor((e.clientHeight - 31) / 30)) + 1;

                this.gridOptions.cacheBlockSize = this.pagination ?
                    blockSize : this.gridOptions.cacheBlockSize;

                this.gridOptions.paginationPageSize = this.pagination ? blockSize : this.gridOptions.paginationPageSize;
                this.gridOptions.infiniteInitialRowCount = this.pagination ? blockSize : this.gridOptions.infiniteInitialRowCount;
            }
            this._currentHeight = e.clientHeight;
        });

        this.collectionViewModel.overlayText.pipe(untilDestroyed(this)).subscribe((overlayText: string) => {
            this.overlayText = overlayText?.length > 0 ? overlayText : null;
            this.cd.detectChanges();
        })
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['collectionViewModel']) {
            this.destroySubscriptions.next();
            if (this.collectionViewModel != null) {

                this.defaultCommandsName = this.defaultCommandsName ?? this.collectionViewModel.getDefaultCommandNames();
                this.collectionViewModel.exportCsvRequested.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions)).subscribe(() => {

                    // this.cd.detectChanges();
                    this.api.exportDataAsCsv({
                        suppressQuotes: false,
                        columnSeparator: ',',
                        processCellCallback: (cell) => {
                            if (cell.value instanceof DateTimeOffset) {
                                return moment(DateTimeOffset.formatToString(cell.value)).format('L') + ' ' + moment(DateTimeOffset.formatToString(cell.value)).format('LT');
                            } else if (cell.value instanceof Date) {
                                return moment(DateTime.formatToString(cell.value)).format('L');
                            }
                            return cell.value;
                        }
                        // customHeader:
                        // customFooter:
                    });
                });

                this.collectionViewModel.openGridSettingsRequested.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions)).subscribe(async () => {

                    const ovm = this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface;
                    const newUserLayout = await ovm.openGridSettings(this.path, this.name, this.getCurrentGridUserLayoutDataDto());

                    if (newUserLayout) {
                        // const newUserLayoutResponse = await firstValueFrom(ovm.apiClient.getUserLayoutMetaDataAsync());
                        // if (newUserLayoutResponse.operationSuccedeed && newUserLayoutResponse.result) {

                        const gridUserLayout = newUserLayout.grids.find((g) => {
                            let camelCasePath = '';
                            const path = this.path ?? '';
                            if (g.path?.length > 0) {
                                camelCasePath = g.path.split('.')
                                    .map((p) => MetaDataUtils.toCamelCase(p))
                                    .join('.');
                            }

                            return MetaDataUtils.toCamelCase(g.name) === this.name && camelCasePath == path

                        });

                        // La grid user layout sarà presente solo nel layout standard
                        if (gridUserLayout?.customizedColumns?.length > 0) {
                            const gridUserLayoutFullPathNameCamelCase = gridUserLayout.fullPathName
                                .split('.')
                                .map((f) => MetaDataUtils.toCamelCase(f))
                                .join('.');

                            const visibilityDefinition = gridUserLayout?.customizedColumns.map((c) => {
                                let temp = c.fullPathName
                                    .split('.')
                                    .map((f) => MetaDataUtils.toCamelCase(f))
                                    .join('.');

                                if (temp.startsWith(gridUserLayoutFullPathNameCamelCase)) {
                                    temp = temp.substring(gridUserLayoutFullPathNameCamelCase.length + 1);
                                }

                                return temp.replace('selectedItem.', '');
                            });

                            const widthDefinition = gridUserLayout?.customizedColumns.map((c) => {
                                let temp = c.fullPathName
                                    .split('.')
                                    .map((f) => MetaDataUtils.toCamelCase(f))
                                    .join('.');

                                if (temp.startsWith(gridUserLayoutFullPathNameCamelCase)) {
                                    temp = temp.substring(gridUserLayoutFullPathNameCamelCase.length + 1);
                                }

                                const fullPropertyName = temp.replace('selectedItem.', '');
                                const isAutoSize = c.isAutoSize;
                                const width = c.width;
                                return {
                                    fullPropertyName, isAutoSize, width
                                };
                            });

                            this.columns = ColumnsUtils.getGridColumns(
                                ovm,
                                this.collectionViewModel.collectionMetaData.dependentMetaData.name,
                                visibilityDefinition,
                                this.columns.editableDefinition,
                                visibilityDefinition,
                                widthDefinition
                            );

                            this.collectionViewModel.columnsChanged.next(true);
                        }
                        // }

                    }

                    // const viewModel = new GridSettingsModalViewModel();
                    // viewModel.columns = this.columns;

                    // const ovm = this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface;

                    // viewModel.modalTitle =
                    //     'Impostazioni ' + (
                    //         this.title?.length > 0 ?
                    //             this.title :
                    //             (
                    //                 ovm.useMessageResourceKey ?
                    //                     MessageResourceManager.Current.getMessage(this.collectionViewModel.collectionMetaData.descriptions.displayNameKey) :
                    //                     this.collectionViewModel.collectionMetaData.descriptions.displayName
                    //             )
                    //     );

                    // const response = await this.modalService.showCustomModalWithResultAsync<boolean>(viewModel, false, false, true, {
                    //     ignoreBackdropClick: true,
                    //     keyboard: true,
                    //     animated: false,
                    //     class: 'grid-settings-modal-container'
                    // });

                    // if (response.result === true) {
                    //     this.collectionViewModel.columnsChanged.next();
                    // }
                });

                this.collectionViewModel.selectionChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions)).subscribe(() => {
                    this.selectNodeFromDomainModelCollectionViewModelSelectedItem();
                    this.cd.detectChanges();
                });

                if (!this.pagination) {
                    this.collectionViewModel.collectionChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions)).subscribe((e: CollectionChangedEventArgs<any>) => {

                        switch (e.action) {
                            case CollectionChangedAction.insert:
                                this.api.stopEditing(false);
                                // this.gridOptions.api.sizeColumnsToFit();
                                this.api.applyTransaction({ add: e.items, addIndex: e.itemsIndex[0] })
                                this.selectNodeFromDomainModelCollectionViewModelSelectedItem();
                                // scrolls to the added row
                                this.api.ensureIndexVisible(this.collectionViewModel.indexOf(e.items[e.items.length - 1]));

                                // console.log('CollectionChangedAction.insert')

                                // this.autoSizeColumns();
                                break;
                            case CollectionChangedAction.set:
                                this.api.stopEditing(false);
                                // this.gridOptions.api.sizeColumnsToFit();

                                this.api.setGridOption('rowData', e.items);
                                this.selectNodeFromDomainModelCollectionViewModelSelectedItem();
                                // scrolls to the added row
                                this.api.ensureIndexVisible(this.collectionViewModel.indexOf(e.items[0]));

                                // console.log('CollectionChangedAction.set')

                                // this.autoSizeColumns();
                                break;
                            case CollectionChangedAction.add:
                                this.api.stopEditing(false);
                                // this.gridOptions.api.sizeColumnsToFit();

                                this.api.applyTransaction({ add: e.items });
                                this.selectNodeFromDomainModelCollectionViewModelSelectedItem();
                                //controllo che l'item sia stato selezionato prima di spostarmi
                                if (this.collectionViewModel.selectedItem == e.items[e.items.length - 1]) {
                                    // scrolls to the added row
                                    this.api.ensureIndexVisible(this.collectionViewModel.indexOf(e.items[e.items.length - 1]));
                                }
                                // console.log('CollectionChangedAction.add')

                                // this.autoSizeColumns();
                                break;
                            case CollectionChangedAction.remove:
                                this.api.stopEditing(false);
                                // this.gridOptions.api.sizeColumnsToFit();

                                this.api.applyTransaction({ remove: e.items });
                                this.selectNodeFromDomainModelCollectionViewModelSelectedItem();

                                // console.log('CollectionChangedAction.remove')

                                // this.autoSizeColumns();
                                break;
                            case CollectionChangedAction.edit:
                                this.api.applyTransaction({ update: e.items });

                                // console.log('CollectionChangedAction.edit')

                                break;
                            case CollectionChangedAction.clear:
                                this.api.stopEditing(false);
                                // this.gridOptions.api.sizeColumnsToFit();

                                this.api.setGridOption('rowData', []);
                                this.api.deselectAll();

                                // console.log('CollectionChangedAction.clear')

                                // this.autoSizeColumns();
                                break;

                        }

                        this.cd.detectChanges();
                    });
                } else {
                    const dcvm = this.collectionViewModel as DatasourceCollectionViewModelInterface<any>;

                    dcvm.datasourceUpdated.pipe(
                        untilDestroyed(this),
                        takeUntil(this.destroySubscriptions),
                    ).subscribe(async () => {

                        if (!this.api) {
                            await firstValueFrom(this.onGridOptionsReady);
                        }

                        this.api.setGridOption('datasource', dcvm.datasource);
                        this.cd.detectChanges();
                    });
                }

                const orchestratorViewModel = this.collectionViewModel.externalRetriever as CoreOrchestratorViewModelInterface;

                // TODO gestire eventi wing e master area per resize colonne
                // this.subscriptions.push(orhcestratorViewModel.eventDispatcher.informationAreaCollapsed.subscribe(() => {
                //     this._gridApi.sizeColumnsToFit();
                // }));

                orchestratorViewModel.currentStateChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions)).subscribe(() => {
                    this.cd.markForCheck();
                });

                this.collectionViewModel.columnsChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions)).subscribe(async (bypassPublicChanges: boolean) => {

                    this.columnDefinitions = this.getUpdatedColumnDefinition();

                    this.api.setGridOption('columnDefs', this.columnDefinitions);

                    // this.autoSizeColumns();

                    if (bypassPublicChanges !== true) {
                        await this.publishColumnChanges(this.columnDefinitions);
                    }

                });

                this.collectionViewModel.propertyChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions), filter((args) => args.propertyName === 'isEnabled')).subscribe(async () => {
                    this.columnDefinitions = this.getUpdatedColumnDefinition();
                    this.api.setGridOption('columnDefs', this.columnDefinitions);
                });

                this.collectionViewModel.propertyChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions), filter((args) => args.propertyName === 'gridFilters')).subscribe(async (args) => {
                  if (args.value === false) {
                    this.api.setFilterModel(null);
                    this.api.setGridOption('quickFilterText', null);

                    if (this.collectionViewModel instanceof BaseRowNumberCollectionViewModel) {
                      this.api.applyColumnState({
                        state: [
                          { colId: "rowNumber.value", sort: "asc", sortIndex: 0 },
                        ],
                        defaultState: { sort: null },
                      });
                    } else {
                      this.api.applyColumnState({
                        defaultState: { sort: null },
                      });
                    }

                  }
                });

                this.collectionViewModel.propertyChanged.pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions), filter((args) => args.propertyName === 'gridSearchFilterValue')).subscribe(async (args) => {
                  if (this.collectionViewModel.gridFilters) {
                    this.api.setGridOption('quickFilterText', args.value);
                  }
                })

                merge(
                    orchestratorViewModel.eventDispatcher.onAddMessageInViewModel,
                    orchestratorViewModel.eventDispatcher.onClearMessagesInViewModel,
                    orchestratorViewModel.eventDispatcher.onRefreshMessageInViewModel,
                    orchestratorViewModel.eventDispatcher.onRemovedMessageInViewModel,
                    orchestratorViewModel.eventDispatcher.onClearAllMessages
                )
                    .pipe(untilDestroyed(this), takeUntil(this.destroySubscriptions))
                    .subscribe(() => {
                        this.cd.markForCheck();
                    })

                this.columnDefinitions = this.getUpdatedColumnDefinition();

                this.collectionViewModelInitialized.emit();
            }
        } else if (changes['minRowNumberHeight']) {
            this.updateDynamicHeight();
        } else if (changes['isHidden']) {
            if (changes['isHidden']?.currentValue === true) {
                this.isHiddenCalculated = true;
            } else {
                this.isHiddenCalculated = !this.collectionViewModel?.isVisible;
            }
        } else if (changes['isDisabled']) {
            this.columnDefinitions = this.getUpdatedColumnDefinition();
            this.api.setGridOption('columnDefs', this.columnDefinitions);
        }
    }

    async addItem() {
        await this.collectionViewModel.addItemCommand.execute();

        // scrolls to the first column
        const firstCol = this.api.getAllDisplayedColumns()[0];
        this.api.ensureColumnVisible(firstCol);
        this.api.setFocusedCell(this.collectionViewModel.length - 1, firstCol);
        this.collectionViewModel.selection = [this.collectionViewModel[this.collectionViewModel.length - 1]];

        // example - get cell renderer for first row and first column
        // var firstRowNode = this.gridOptions.api.getDisplayedRowAtIndex(this.collectionViewModel.length - 1);
        // var params = { columns: [firstCol], rowNodes: [firstRowNode] };
        // // var instances = this.gridOptions.api.getCellRendererInstances(params);
        // var instances = this.gridOptions.api.getCellEditorInstances(params);
        // if (instances.length > 0) {
        //     // got it, user must be scrolled so that it exists
        //     const wrapperInstance: any = instances[0];
        //     wrapperInstance.setFocus();

        //     // var frameworkInstance = wrapperInstance.getFrameworkComponentInstance();
        // }
    }

    async onEnterGrid() {

        // se entro nella griglia e ci sono zero righe, creo prima un nuovo elemento
        if (this.autoCreateRow && this.collectionViewModel.length === 0 && this.collectionViewModel.addItemCommand.canExecute$.value) {
            this.collectionViewModel.collectionChanged.pipe(untilDestroyed(this), take(1), delay(50)).subscribe(() => this.focusOnFirstRow());
            await this.collectionViewModel.addItemCommand.execute();
        } else {
            this.focusOnFirstRow();
        }
    }

    changeDynamicHeight(height: number): void {
        if (this.dynamicHeight) {
            const part = this.hostElement.nativeElement.querySelector('.ag-root-wrapper-body');
            if (part) {
                this.renderer.setStyle(part, 'height', `unset`);
                this.renderer.setStyle(part, 'max-height', `${height}px`);
            }
        } else {
            const part = this.hostElement.nativeElement.querySelector('.ag-root-wrapper-body');
            if (part) {
                this.renderer.setStyle(part, 'height', `${height}px`);
                this.renderer.setStyle(part, 'max-height', `${height}px`);
            }
        }
    }

    onCellKeyDown(e: CellKeyDownEvent | FullWidthCellKeyDownEvent) {

        // console.log('onCellKeyDown', e)

        if (e.event) {
            const KeyboardEvent = (e.event as KeyboardEvent);
            const keyPressed = KeyboardEvent.key;

            // se ho premuto la barra
            if (keyPressed === ' ') {

                // recupero la cella corrente
                const currentCell = this.api.getFocusedCell();

                // recupero la definizione della colonna
                const colDef = currentCell.column.getColDef();

                // nel caso in cui sono in un bool
                if ((colDef.cellClass as string).indexOf('Bool') > -1) {

                    // recupero la row
                    const row = this.api.getDisplayedRowAtIndex(currentCell.rowIndex);
                    if (currentCell?.column?.isCellEditable(row)) {
                        // recupero il valore corrente
                        const cellValue = this.api.getValue(currentCell.column, row) || false;

                        // imposto il valore alla cella
                        row.setDataValue(currentCell.column, !cellValue)
                    }
                }
            }

            if (KeyboardEvent.ctrlKey && keyPressed.toLowerCase() == 'c') {
                // recupero il valore della cella corrente e lo copio negli appunti
                const currentCell = this.api.getFocusedCell();
                const row = this.api.getDisplayedRowAtIndex(currentCell.rowIndex);
                const cellValue = this.api.getValue(currentCell.column, row)
                navigator.clipboard.writeText(cellValue).then(() => {
                    // Alert the user that the action took place.
                    // Nobody likes hidden stuff being done under the hood!
                    //alert("Copied to clipboard");
                });
            }

            if (KeyboardEvent.ctrlKey && keyPressed.toLowerCase() == 'v') {
                // incollo il valore negli appunti nella cella attuale
                navigator.clipboard.readText().then(text => {
                    const currentCell = this.api.getFocusedCell();
                    const row = this.api.getDisplayedRowAtIndex(currentCell.rowIndex);
                    row.setDataValue(currentCell.column, text);
                })
                    .catch(err => {
                        console.error('Failed to read clipboard contents: ', err);
                    });
            }
        }
    }

    updateDynamicHeight() {

        // Disabilito questa funzionalità se minRowNumberHeight === 0
        if (this.minRowNumberHeight > 0) {
            this.changeDynamicHeight((this.minRowNumberHeight * this.ROW_HEIGHT) + 33);
        }
    }

    // Se sono in modalità edit non devo fare il preventDefault dello space, altrimenti non reisco ad inserirlo nelle celle
    preventScrollWithoutFocus(e) {
        const editing = this.api.getEditingCells();
        if (!editing || editing.length === 0) {
            e.preventDefault();
        }
    }

    onColumnResized(e: ColumnResizedEvent) {
        // fino a quando non ho finito il resize e le notifiche non arrivano dal ridimensionamento automatico delle colonne flex
        if (!e.finished || e.source === "flex") {
            return
        }

        if (!e.column) {
            e.column = e.columns[0];
        }
        // this.updateGridStateRequested.next();
        this.columnsWidthState[e.column.getColId()] = {
            actualWidth: e.column.getActualWidth(),
            flex: e.column.getFlex()
        }
        this.publishColumnChanges();
    }

    isSecurityAccessDeny(): boolean {
        return this.collectionViewModel.securityAccess === AccessMode.Deny;
    }

    isSecurityAccessReadOnly(): boolean {
        return this.collectionViewModel.securityAccess === AccessMode.ReadOnly;
    }

    protected getCellRendererParams(columnInfo) {
        return { columnInfo };
    }

    protected getCellRendererComponent(columnInfo: ColumnInfo): any {

        // TODO gestire in ogni componente la possibilità di visualizzare il loaderrendere quando il params.value == null
        // e this.pagination = true

        switch (columnInfo.propertyTypeName) {
            case 'ExternalDecode-Bool':
            case 'ExternalDecode-DateTime':
            case 'ExternalDecode-Enum':
            case 'ExternalDecode-Numeric':
            case 'ExternalDecode-String':
            case 'ExternalCode-Numeric':
            case 'ExternalCode-String':
            case 'ExternalCodeRef':
                return ExternalCellRendererComponent;
            case 'Command':
                return CommandCellRendererComponent;
        }
        return DynamicCellRendererComponent;
    }

    private focusOnFirstRow() {
        // scrolls to the first row
        this.api.ensureIndexVisible(0);

        // scrolls to the first column
        var firstCol = this.api.getAllDisplayedColumns()[0];
        this.api.ensureColumnVisible(firstCol);

        // sets focus into the first grid cell
        this.api.setFocusedCell(0, firstCol);
    }

    private selectNodeFromDomainModelCollectionViewModelSelectedItem(): void {
        this.api?.forEachNode && this.api.forEachNode(node => {

            const selected = node.data === this.collectionViewModel.selectedItem;
            node.setSelected(selected);
            if (selected) {
                this.api.ensureIndexVisible(node.rowIndex);
            }
        });
    }

    private setGrouping(): void {

        const groupCols = this.columns.filter(c => c.groupIndex > -1).sort((a, b) => a.groupIndex - b.groupIndex);

        if (groupCols.length > 0) {

            const defaultSortModel = [];

            groupCols.forEach(c => {
                defaultSortModel.push({
                    colId: c.fieldName,
                    sort: 'asc'
                });
            });


            this.api.applyColumnState({
                state: defaultSortModel,
                defaultState: { sort: null },
            });
        }
    }

    private getUpdatedColumnDefinition() {
        const columnDefinitions = [];

        // Sort columns
        // this.columns.sort((columnA: InfraColumnInfo, columnB: InfraColumnInfo) => {
        //     if (columnA.position < columnB.position)
        //         return -1;
        //     if (columnA.position > columnB.position)
        //         return 1;
        //     return 0;
        // })

        const columns = this.columns.concat(this.collectionViewModel.getCommandColumns());

        for (let i = 0; i < columns.length; i++) {
            // let valueGetter = this.columns[i].propertyName;
            let field = columns[i].propertyName;

            // TODO Tommy external
            if (columns[i] instanceof ExtColumnInfo) {
                field = (columns[i] as ExtColumnInfo).path;
                // valueGetter = (<ExtInfraColumnInfo>this.columns[i]).path + '.' + this.columns[i].propertyName;
            }

            const colDef: ColDef = {
                colId: columns[i].fieldName,
                headerName: columns[i].header,
                headerTooltip: columns[i].headerTooltip,
                filter: true,
                filterParams: {
                  buttons: ["reset"],
                } as ITextFilterParams,
                valueGetter: (params) => {
                    if (params.data == null) {
                        return '';
                    }
                    const result = columns[i].fieldName.split('.').reduce((o, j, currentIndex, arr) => {
                        if (o == null) {
                            return null;
                        }
                        if (j === 'value' && (this.isDisabled || this.simpleRenderer || this.collectionViewModel.securityAccess != null)) {
                            return o['formattedValue'];
                        }
                        if (j !== 'value' && o[j] == null) {
                            const path = columns[i].fieldName;
                            const splitPath = path.split('.');
                            const found = splitPath.findIndex((p) => p === j);
                            let className = params.data.constructor.name;
                            if (found > 0) {
                                splitPath.forEach((p, index) => {
                                    if (index < found) {
                                        className += '->' + p;
                                    }
                                });
                            }
                            throw new Error(`${className} non ha la property ${j}`);
                        }
                        if (j === 'value' && o[j] != null) {
                            if (o instanceof BaseNumericPropertyViewModel) {
                                return o['formattedValue'];
                            }
                            if (o instanceof BaseEnumPropertyViewModel) {
                                return o['formattedValue'];
                            }
                            if (o instanceof BaseDateTimePropertyViewModel) {
                                return DateTime.formatToString(o['value']);
                            }
                            if (o instanceof BaseDateTimeOffsetPropertyViewModel) {
                                return DateTimeOffset.formatToString(o['value']);
                            }
                        }
                        // gestisco i campi ref
                        if (j !== 'value' && o[j] instanceof ExternalViewModel && currentIndex === arr.length - 1) {
                            const columnInfo: ExtColumnInfo = columns[i] as ExtColumnInfo;
                            return o[j].getDescription(
                                columnInfo.showCodeInDescription,
                                columnInfo.showProperties,
                                columnInfo.joinOperator
                            );
                        }
                        return o[j];
                    }, params.data);
                    return result;
                },
                cellClassRules: {
                    'cell-error': (params: CellClassParams) => {
                        if (!this.showErrorsInCells) {
                            return false;
                        }

                        if (params?.data && params?.colDef?.cellClass && params?.colDef?.cellClass?.toString().startsWith('cell-ExternalCodeRef')) {
                            const splittedPath = columns[i]?.fieldName?.split('.');
                            if (splittedPath) {
                                const obj = splittedPath?.slice(0, splittedPath?.length).reduce((o, j) => o[j], params?.data);
                                return (obj as ExternalViewModelInterface)?.hasErrors;
                            }
                        } else if (params?.data && params?.colDef?.cellClass && params?.colDef?.cellClass?.toString().startsWith('cell-ExternalCode')) {
                            const splittedPath = columns[i]?.fieldName?.split('.');
                            if (splittedPath) {
                                const obj = splittedPath?.slice(0, splittedPath?.length - 1).reduce((o, j) => o[j], params?.data);
                                return (obj as PropertyViewModelInterface)?.parent?.hasErrors;
                            }
                        } else if (params?.data && params?.colDef?.cellClass && params?.colDef?.cellClass !== 'cell-ExternalDecode') {
                            const splittedPath = columns[i]?.fieldName?.split('.');
                            if (splittedPath) {
                                const obj = splittedPath?.slice(0, splittedPath?.length - 1).reduce((o, j) => o[j], params?.data);
                                return (obj as PropertyViewModelInterface)?.hasErrors;
                            }
                        }
                        return false;
                    }
                },
                valueSetter: (params) => {
                    if (params.oldValue != params.newValue && params.colDef.cellClass !== 'cell-ExternalDecode') {
                        const splittedPath = columns[i].fieldName.split('.');
                        const obj = splittedPath.slice(0, splittedPath.length - 1).reduce((o, j) => o[j], params.data);

                        if (obj instanceof NNumericPropertyViewModel && params.newValue === '') {
                            params.newValue = null;
                        } else if (obj instanceof BaseNumericPropertyViewModel) {
                            if (typeof (params.newValue) == 'string') {
                                params.newValue = obj.getNumeric(params.newValue)
                            }
                        }

                        if (obj instanceof BaseDateTimePropertyViewModel) {
                            if (typeof (params.newValue) == 'string') {
                                params.newValue = DateTime.formatFromString(params.newValue)
                            }
                        }

                        if (obj instanceof BaseDateTimeOffsetPropertyViewModel) {
                            if (typeof (params.newValue) == 'string') {
                                params.newValue = DateTimeOffset.formatFromString(params.newValue)
                            }
                        }

                        if ((params?.colDef?.cellClass as string)?.startsWith('cell-ExternalCodeRef')) {
                            const identity = params?.newValue?.identity;
                            if (identity) {
                                const foundKey = Object.keys(identity).find(key => key.toLowerCase() === (obj as PropertyViewModelInterface)?.propertyName?.toLowerCase());
                                if (foundKey) {
                                    (obj as PropertyViewModelInterface).setValue(identity[foundKey]);
                                }
                            }
                        } else {
                            (obj as PropertyViewModelInterface).setValue(params.newValue);
                        }


                        return true;
                    }
                    return false;
                },
                // onCellClicked: (event: CellClickedEvent) => {
                //     console.log(event);
                //     event.colDef.cellEditorParams.cellClicked = true;
                // },
                singleClickEdit: columns[i]?.propertyTypeName?.toLowerCase()?.indexOf('bool') > -1,
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
                    // return true (to suppress) if editing and user hit arrow keys

                    const event = params.event;
                    const key = event.key;
                    const gridShouldDoNothing = (params.editing && (key === KEY_DOWN || key === KEY_LEFT || key === KEY_UP || key === KEY_RIGHT)) || key === KEY_SPACE || key === KEY_DELETE;
                    return gridShouldDoNothing;
                },
                cellClass: 'cell-' + columns[i].propertyTypeName + ((this.isDisabled || this.simpleRenderer || this.collectionViewModel.securityAccess != null) ? '-simple-renderer' : ''),
                editable: (params) => {
                    const isEditable = (this.collectionViewModel?.securityAccess != null || this.columns[i].securityAccess !== null) ? false : (this.collectionViewModel.isEnabled && this.isDisabled !== true ?
                        columns[i].isEnabled :
                        false);

                    if (isEditable && params?.data != null) {
                        const result = columns[i].fieldName.split('.').reduce((o, j) => {
                            if (j === 'value') {
                                return o['isEnabled'] === true;
                            }
                            return o[j];
                        }, params.data);
                        return result;
                    }
                    return isEditable;
                },
                hide: !columns[i].isVisible,
                cellEditor: this.getCellEditorComponent(columns[i]),

                /**
                 * The column property suppressMovable changes whether the column can be dragged.
                 * The column header cannot be dragged by the user to move the columns when suppressMovable=true.
                 * However the column can be inadvertently moved by placing other columns around it thus only making it practical if all columns have this property.
                 */
                suppressMovable: true,
                minWidth: 40,
                width: this.columnsWidthState[columns[i].fieldName]?.actualWidth ?? columns[i].width,
                flex: this.columnsWidthState[columns[i].fieldName]?.flex ?? columns[i].isAutoSize ? 1 : 0,
                cellEditorParams: {
                    context: this,
                    columnInfo: columns[i],
                    cellClicked: false
                },
                sortingOrder: this.collectionViewModel instanceof BaseRowNumberCollectionViewModel && columns[i].fieldName === 'rowNumber.value' ? ['asc'] : ['asc', 'desc', null],
                sortable: true,
                cellRenderer: this.getCellRenderer(columns[i]),
                cellRendererParams: (this.isDisabled || this.simpleRenderer || this.collectionViewModel.securityAccess != null) ? null : this.getCellRendererParams(columns[i]),
                headerComponent: this.getCellHeaderComponent(columns[i]),
                headerComponentParams: {
                    columnInfo: columns[i],
                    gridFilters$: this.collectionViewModel?.gridFilters$
                }
                // suppressSorting: columns[i].groupIndex !== -1
            };

            if (columns[i].propertyTypeName === 'Command') {
                colDef.editable = false;
            }
            columnDefinitions.push(colDef);
        }

        if (this.collectionViewModel instanceof BaseRowNumberCollectionViewModel && columnDefinitions.findIndex((col) => col.colId === 'rowNumber.value') === -1) {


            const fieldName = 'rowNumber.value';
            const rowNumberColDef: ColDef = {
                colId: 'rowNumber.value',
                sortingOrder: ['asc'],
                valueGetter: (params) => {
                    if (params.data == null) {
                        return '';
                    }
                    const result = fieldName.split('.').reduce((o, j) => {
                        // TODO attenzione verificare il funzionamento degli external con simplerentere = true
                        if (j === 'value' && (this.isDisabled || this.simpleRenderer || this.collectionViewModel.securityAccess != null)) {
                            return o['formattedValue'];
                        }
                        if (j !== 'value' && o[j] == null) {
                            const path = fieldName;
                            const splitPath = path.split('.');
                            const found = splitPath.findIndex((p) => p === j);
                            let className = params.data.constructor.name;
                            if (found > 0) {
                                splitPath.forEach((p, index) => {
                                    if (index < found) {
                                        className += '->' + p;
                                    }
                                });
                            }
                            throw new Error(`${className} non ha la property ${j}`);
                        }
                        if (j === 'value' && o[j] != null) {
                            if (o instanceof BaseNumericPropertyViewModel) {
                                return o['formattedValue'];
                            }
                            if (o instanceof BaseDateTimePropertyViewModel) {
                                return DateTime.formatToString(o['value']);
                            }
                            if (o instanceof BaseDateTimeOffsetPropertyViewModel) {
                                return DateTimeOffset.formatToString(o['value']);
                            }
                        }
                        return o[j];
                    }, params.data);
                    return result;
                },
                sortable: true,
                hide: true
            };

            columnDefinitions.splice(0, 0, rowNumberColDef);

        }

        return columnDefinitions;
    }

    getCellRenderer(column: ColumnInfo | ExtColumnInfo) {
        return (this.isDisabled || this.simpleRenderer || this.collectionViewModel.securityAccess != null) ?
            this.getReadOnlyCellRenderer() :
            this.getEditableCellRenderer(column)
    }

    getReadOnlyCellRenderer() {
        return this.pagination ? 'loadingRenderer' : null;
    }

    getEditableCellRenderer(column: ColumnInfo | ExtColumnInfo) {
        return this.getCellRendererComponent(column);
    }

    private getCellEditorComponent(columnInfo: ExtColumnInfo | ColumnInfo): any {
        switch (columnInfo.propertyTypeName) {

            // case 'ExternalCode-Numeric':
            //     return ExternalNumericCellEditorComponent;
            // case 'ExternalCode-String':
            case 'ExternalCodeRef':
                if ((columnInfo as ExtColumnInfo).inputMode === ExternalFieldInputMode.ExternalList) {
                    return ExternalListCellEditorComponent;
                }
                return ExternalStringCellEditorComponent;
            // Gli external decode saranno sempre in solo lettura quindi non entreranno mai in editing
            // case 'ExternalDecode':
            //     return StringCellEditorComponent;

            // case 'Numeric':
            //     return NumericCellEditorComponent
        }
        return DynamicCellEditorComponent;
    }

    private getCellHeaderComponent(columnInfo: ColumnInfo): any {
        return CustomHeaderComponent;
    }

    private getCurrentGridUserLayoutDataDto(columnDefinitions?: ColDef[]): GridUserLayoutDataDto {
        const columnDefs = this.api.getColumnState();
        columnDefinitions = columnDefinitions ?? this.getUpdatedColumnDefinition();
        const dto = new GridUserLayoutDataDto();
        dto.gridMetaData.name = this.name;
        dto.gridMetaData.path = this.path;
        let position = 1;

        ColumnsUtils.sortColumnByArray(
            this.columns.filter(c => c.isVisible === true),
            columnDefs
                .filter(c => c.hide === false)
                .map((d) => d.colId.replace('.value', '')));

        for (const c of columnDefs) {

            // Costruisco il layout column meta data
            const customizedColumn = new UserLayoutColumnMetaData();
            const splittedColId = c.colId.split('.');
            if (splittedColId.length > 2) {
                const path = splittedColId.filter((x, index) => index < splittedColId.length - 2).join('.')
                customizedColumn.path = path;

            }
            customizedColumn.isUserVisible = !c.hide;
            customizedColumn.name = splittedColId[splittedColId.length - 2] ? splittedColId[splittedColId.length - 2] : splittedColId[0];
            customizedColumn.position = position++;
            customizedColumn.isAutoSize = columnDefinitions.find((cd) => cd.colId === c.colId)?.flex === 1;
            customizedColumn.width = columnDefinitions.find((cd) => cd.colId === c.colId)?.width;
            dto.gridMetaData.customizedColumns.push(customizedColumn)
        }
        return dto;
    }

    private async publishColumnChanges(columnDefinitions?: ColDef[]): Promise<void> {
        this.publishColumnChangesDebouncer.next(columnDefinitions);
    }

    private handleVisibleRowChanged() {
        //prendo l'header della grid
        const headerElement = this.hostElement.nativeElement.querySelectorAll('.ag-header');
        //prendo l'intera grid
        const agGrid = this.hostElement.nativeElement.querySelectorAll('ag-grid-angular');
        //calcolo l'altezza del body della grid ( la sezione in cui vengono messe le righe)
        const gridBodyHeight: number = (agGrid[0]?.offsetHeight - headerElement[0]?.offsetHeight) - 2;

        //chiamo il metodo che esegue i controlli
        this.handleModeUpdateAndScrollEvent(gridBodyHeight);

        this.visibleRowsChange.next({ top: 0 });
    }

    private handleModeUpdateAndScrollEvent(gridBodyHeight: number) {
        //definisco una variabile per la posizione della scrollbar della grid
        let scrollPosition;

        //quando si scrolla
        this.gridOptions.onBodyScroll = (event) => {
            //controllo solo lo scroll verticale
            if (event.direction === 'vertical') {
                //salvo la posizione della scrollbar
                scrollPosition = event.top;
                this.visibleRowsChange.next(event);
            }
        };
        this.gridOptions.onModelUpdated = (event) => {
            this.visibleRowsChange.next({ top: scrollPosition });
        };

        this.visibleRowsChange
            .pipe(untilDestroyed(this), debounceTime(300))
            .subscribe(v => {

                let topIndex = v.top / this.ROW_HEIGHT;
                let bottomIndex = ((v.top + gridBodyHeight) / this.ROW_HEIGHT) - 1;

                const viewportTop = scrollPosition;
                const viewportBottom = gridBodyHeight + scrollPosition;

                if (viewportTop % this.ROW_HEIGHT != 0) topIndex -= 1;
                if (viewportBottom % this.ROW_HEIGHT != 0) bottomIndex += 1;

                const visibleRowNodes = this.api.getRenderedNodes()?.filter(node => node.rowIndex >= topIndex && node.rowIndex <= bottomIndex);
                if (visibleRowNodes) {
                    this.collectionViewModel.visibleRowsChanged.next(visibleRowNodes.map((node) => node.data))
                }
            });
    }

}
