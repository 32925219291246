import { ViewModel } from './view-model';
import { BaseIdentity } from '../domain-models/base-identity';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { StringDecorator } from '../domain-models/decorators/string.decorator';
import { StringMetaData } from '../meta-data/string-meta-data';
import { AggregateMetaData } from '../meta-data/aggregate-meta-data';
import { CustomPropertyViewModelDecorator } from './decorators/custom-property-view-model.decorator';
import { MasterViewModelInterface } from './master-view-model.interface';
import { OrchestratorViewModelInterface } from './orchestrator-view-model.interface';
import { PropertyViewModelPropertyChangedEventArgs } from './property-view-property-changed-event-args';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, debounceTime, tap, filter, map } from 'rxjs/operators';
import { SearchStringPropertyViewModel } from './base-type/search-string-property-view-model';
import { AutoCompleteModelOptions, OrderBy, OrderByType, CoreModel, Filter, GridUserLayoutDataDto, ZoomResult, AutoCompleteOptions } from '../domain-models';
import { CollectionViewModelTypeInspector } from './decorators/collection-view-model-type.decorator';
import { SearchResultlCollection } from '../domain-models/search-result.collection';
import { BaseViewModelInterface } from './base-view-model.interface';
import { ColumnInfoCollection } from './column-info-collection';
import { ColumnsUtils } from './columns_utils';
import { ColumnsProviderInterface } from './columns_provider.interface';
import { ColumnsProvider } from './columns-provider';
import { DomainModelMetaData, InternalCollectionMetaData, InternalRelationMetaData } from '../meta-data/domain-model-meta-data';
import { ViewModelStates } from './states/view-model-states';
import { MasterDetailOrchestratorViewModelInterface } from './master-detail-orchestrator-view-model.interface';
import { PropertyMetaData } from '../meta-data/property-meta-data';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { MasterDetailRootViewModel } from './master-detail-root-view-model';
import { ViewModelFactory } from './view-model-factory';
import { ViewModelInterface } from './view-model.interface';
import { RootViewModelTypeInspector } from '../decorators/root-view-model-type.decorator';
import { PropertyViewModel } from './property-view-model';
import cloneDeep from 'lodash-es/cloneDeep';
import { ExternalViewModel } from './external-view-model';
import { AggregateElementViewModel } from './aggregate-element-view-model';
import { ExtendedFilterAwareInterface } from './extended-filter-aware.interface';
import { MetaDataUtils } from '../meta-data/meta-data-utils';
import { ClassInformationInterface, ClassInformationType } from '@nts/std/utility';
import { AccessMode } from '../meta-data/access-mode.enum';
import { BaseEnumPropertyViewModel } from './base-type/enum-property-view-model';
import { MasterViewModelSearchResultCollectionViewModel } from './master-view-model-search-result.collection-view-model';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { EventEmitter } from '@angular/core';
import { ClassConstructor } from '@nts/std/serialization';
import { ToastMessageType } from '../components';

export class MasterViewModel<
    TRootViewModel extends MasterDetailRootViewModel<TModel, TIdentity>,
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends ViewModel<TModel, TIdentity>
    implements MasterViewModelInterface, ExtendedFilterAwareInterface, ClassInformationInterface {

    //#region PUBLIC VARS
    masterDetailGridName = 'masterDetailGrid'
    classType = ClassInformationType.MasterViewModel;
    // passa true se è il primo caricamento
    searchCompleted: Subject<boolean> = new Subject<boolean>();
    selectionChanging: Subject<TModel> = new Subject<TModel>();
    selectionChanged: Subject<TRootViewModel> = new Subject<TRootViewModel>();
    selectionUpdated: Subject<TRootViewModel> = new Subject<TRootViewModel>();
    actionInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    onSearchGridOptionsReady$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    searchPropertyNames = [];
    extendedFilter: TRootViewModel;
    searchResult: MasterViewModelSearchResultCollectionViewModel<TRootViewModel, TModel, TIdentity>;
    extendedFiltersList: {
        property: any,
        descriptionProperty?: any
        codeProperty?: any
        showCode?: boolean
    }[] = [];

    private _enableExtendedFilter = false;

    get enableExtendedFilter(): boolean {
        return this._enableExtendedFilter;
    }

    set enableExtendedFilter(value: boolean) {
        this._enableExtendedFilter = value;
        this.onPropertyChanged('enableExtendedFilter');
    }

    private _showExtendedFilter = false;

    get showExtendedFilter(): boolean {
        return this._showExtendedFilter;
    }

    set showExtendedFilter(value: boolean) {
        this._showExtendedFilter = value;
        this.onPropertyChanged('showExtendedFilter');
    }

    override get currentState(): ViewModelStates {
        return ViewModelStates.Unchanged;
    }

    override get domainModel(): TModel {
        throw new Error('Master view model hasn\'t own domain model!');
    }
    override set domainModel(value: TModel) {
        throw new Error('Master view model hasn\'t own domain model!');
    }

    get isSelected() {
        return this._isSelected;
    }

    get isFullScreen() {
        return this._isFullScreen;
    }

    set isFullScreen(value) {
        this._isFullScreen = value;

        for (const column of this.searchResultColumns) {
            this._orchestratorViewModel.applyVisibilityLogicToColumn(column, this._isFullScreen);
            this._orchestratorViewModel.applyCustomVisibilityLogicToColumn(column, this._isFullScreen);
        }

        if (value !== true && this.searchResult.selection.length > 0) {
            // se ho cambiato selezione durante lo stato di fullscreen, aggiorno la selezione quando esco dal fullscreen
            const selectedDomainModel = this.searchResult.selection[0].getDomainModel();
            if (!selectedDomainModel.currentIdentity.equals(this.selectedItem?.getDomainModel()?.currentIdentity)) {
                this.selectionChanging.next(selectedDomainModel);
            }
        }
        this.searchResult.columnsChanged.emit();
    }

    // TODO Tommy passare da model a viewmodel quando si avrà la collection?
    get selectedItem() {
        return this._selectedItem;
    }

    get searchResultColumns(): ColumnInfoCollection {
        return this._searchResultColumns;
    }

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'std_MasterViewModel_Search_displayName' })
    get search(): SearchStringPropertyViewModel {
        if (this._search == null) {
            const init = this.createPVMInitializationInfo<StringMetaData>('search', null, this, false);
            this._search = new SearchStringPropertyViewModel(init, this);
        }
        return this._search;
    }

    get searchInProgress(): boolean {
        return this._searchInProgress;
    }
    //#endregion PUBLIC VARS

    //#region PROTECTED VARS
    protected unsubscribe$: Subject<boolean> = new Subject();
    //#endregion PROTECTED VARS

    //#region PRIVATE VARS
    private _columnsProvider: ColumnsProviderInterface;
    private _rootDomainModelType: any;
    private _mainProperties: PropertyMetaData[] = [];
    private _orchestratorViewModel: MasterDetailOrchestratorViewModelInterface;
    private _isSelected = false;
    private _isFullScreen = false;
    private _selectedItem: TRootViewModel;
    private _searchResultColumns: ColumnInfoCollection;
    private _search: SearchStringPropertyViewModel;
    private _searchInProgress = false;
    //#endregion PRIVATE VARS

    //#region PUBLIC METHODS
    override async postInit(): Promise<void> {
        this.search.propertyChanged.pipe(
            takeUntil(this.unsubscribe$),
            filter((args) => args.propertyName == this.search.bindedValuePropertyName),
            debounceTime(500),
        ).subscribe((args: PropertyViewModelPropertyChangedEventArgs) => {
            this.execSearch(args.value);
        });

        this.searchResult.selectionChanged
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((selection) => {
                if (selection.length > 0 && !this.isFullScreen) {
                    this.selectionChanging.next(selection[0].getDomainModel());
                }
            });

        this.searchResult.paginatedCollectionItemsLoaded
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((firstLoad) => {
                this._searchInProgress = false;
                this.searchCompleted.next(firstLoad);
                this.actionInProgress$.next(false);
            });

        this.searchResult.paginatedCollectionItemsError
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((firstLoad) => {
                this._searchInProgress = false;
                this.searchCompleted.next(firstLoad);
                this.actionInProgress$.next(false);
            });
    }

    override onDestroy() {
        super.onDestroy();
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    // non usare questa funzione per selezionare elementi della searchResult ma intervenire direttamente
    // su this.masterViewModel.searchResult.selection = [];
    setSelectedItem(newItem: TRootViewModel) {
        this._selectedItem = newItem;
        this.selectionChanged.next(newItem);
    }

    async onSearchColumnsChanged(dto: GridUserLayoutDataDto) {
        dto.gridMetaData.name = this.masterDetailGridName
        await this._orchestratorViewModel.setGridUserLayoutDataAsync(dto);
    }

    async setFilters(filters: string[]) {
        this.enableExtendedFilter = filters.length > 0;
        for (let i = 0; i < filters.length; i++) {

            const pvm = this.extendedFilter.getProperty(filters[i]);

            if (pvm instanceof PropertyViewModel) {
                pvm.isEnabled = true;

                pvm.canNotifyModified = false;

                pvm.propertyChanged.pipe(
                    filter((e) => e.propertyName == pvm.bindedValuePropertyName),
                    takeUntil(this.unsubscribe$),
                    tap((args: PropertyViewModelPropertyChangedEventArgs) => {
                        this.setAutoCompleteFilter(filters[i], args.value);
                    }),
                    debounceTime(500))
                    .subscribe((args: PropertyViewModelPropertyChangedEventArgs) => {
                        this.execSearch(this.search.value, true);
                    });
                this.extendedFiltersList.push({ property: pvm });
            }

            if (!pvm) {
                const external = this.extendedFilter.relationViewModels.get(filters[i]);
                if (external && external instanceof ExternalViewModel) {
                    external.isEnabled = true;
                    external.isMock = false;
                    external.isRequired = false;
                    external.notifyErrorToDispatcher = false;
                    external.automaticDecodeDescription.canNotifyModified = false;
                    external.backingFieldCanNotifyModified = false;
                    external.codeProperties.forEach((code) => {
                        code.canNotifyModified = false;
                    });

                    external.descriptionProperties.forEach((description) => {
                        description.canNotifyModified = false;
                    });

                    external.externalDomainModelChanged.pipe(
                        takeUntil(this.unsubscribe$),
                        map(() => {
                            external.codeProperties.forEach((code) => {
                                // invece di usare il path dell'external ref.property uso il suo backing field
                                const extVm: ExternalViewModelInterface = code.parent as ExternalViewModelInterface;
                                const externalMetaData = extVm.externalMetaData;
                                let propertyName = code.propertyName;
                                const association = externalMetaData.associationProperties.find((a) => MetaDataUtils.toCamelCase(a.dependentPropertyName) === code.propertyName);
                                if (association) {
                                    propertyName = association.principalPropertyName;
                                }

                                this.setAutoCompleteFilter(propertyName, code.getValue());
                            });
                            return external.hasDecodeError;
                        }),
                        debounceTime(500)
                    ).subscribe((hasDecodeError: boolean) => {
                        if (!hasDecodeError) {
                            this.execSearch(this.search.value, true);
                        }
                    });

                    this.extendedFiltersList.push({
                        property: external,
                        descriptionProperty: external.automaticDecodeDescription,
                        codeProperty: external.getProperty(external.codeProperties.keys().next().value),
                        showCode: external.showCode
                    });
                }
            }
        }
    }

    async initSearchResultColumns(): Promise<void> {
        const rootViewModelItem = await this.searchResult.createItem(this._orchestratorViewModel.mockedRootDomainModel as TModel, true);
        const domainModelTypeName = this.aggregateMetaData.rootMetaData.name;
        let columnList = this.aggregateMetaData.rootMetaData.propertyNames

            // transforma in camel case
            .map((p) => MetaDataUtils.toCamelCase(p))

            // Le filtro con le property che ho nel root view model
            .filter((p) => rootViewModelItem.getProperties().map((pp) => pp.propertyName).indexOf(p) > -1)

            // Excludo le property di sistema
            .filter((p) => ColumnsUtils.getExcludedSystemProperties().findIndex((f: string) => MetaDataUtils.toCamelCase(f) === p) === -1);

        // Eventualmente aggiungo le custom columns
        columnList = this._orchestratorViewModel.getSearchResultColumnList(
            [...this._orchestratorViewModel.getCustomSearchColumn(), ...columnList]
        )
        // rimuovo i duplicati
        .filter((elem, index, self) => index === self.indexOf(elem) );

        const columnOrderList = this._orchestratorViewModel.getSearchResultOrderList(columnList);

        this._searchResultColumns = this.getGridColumns(
            domainModelTypeName, columnList, false, columnOrderList
        );

        this._mainProperties = this._orchestratorViewModel.getMainProperties().map((p) =>
            this.aggregateMetaData.rootMetaData.getPropertyMetaData(p)
        ).filter((isNotNull) => isNotNull);

        for (const column of this._searchResultColumns) {
            this._orchestratorViewModel.applyVisibilityLogicToColumn(column, this.isFullScreen);
            this._orchestratorViewModel.applyCustomVisibilityLogicToColumn(column, this.isFullScreen);
        }
    }

    removeItem(identity: TIdentity) {
        const index = this.searchResult.findIndex((el: TRootViewModel) => el.getDomainModel().currentIdentity.equals(identity));
        if (index > -1) {
            this.searchResult.remove(index, true, true);
        }
    }

    updateSelectedItem(newItem: TRootViewModel) {
        // se ho selezionato un elemento in precedenza
        if (this._selectedItem) {
            Object.assign(this._selectedItem, newItem);
            this.selectionUpdated.next(newItem);
        }
    }

    async initMasterViewModel(
        domainModel: TModel,
        aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: MasterDetailOrchestratorViewModelInterface,
        rootDomainModelType: any
    ) {
        this.aggregateMetaData = aggregateMetaData;
        this._modifiedSubscriber = orchestratorViewModel;
        this._orchestratorViewModel = orchestratorViewModel;
        this._rootDomainModelType = rootDomainModelType;
        this.externalRetriever = orchestratorViewModel;

        this._orchestratorViewModel.eventDispatcher.onMasterAreaSelected
            .subscribe((isSelected) => {
                if (isSelected === false && this.searchResult) {
                    this.searchResult.exitFocusGridCommand.execute();
                } else if (isSelected === true) {
                    this._orchestratorViewModel.eventDispatcher.onSnapShotSelected.next(false);
                }
                this._isSelected = isSelected;
            });

        const isSelected = this.aggregateMetaData.rootMetaData.userMetaData?.securityAccess !== AccessMode.Deny;
        this._orchestratorViewModel.eventDispatcher.onMasterAreaSelected.next(isSelected);

        await this.initMasterElementViewModel(domainModel, aggregateMetaData, orchestratorViewModel);
        await this.initSearchResultColumns();
    }

    getGridColumns(
        entityTypeName: string,
        visibilityDefinition: string[] = null,
        editableDefinition: string[] | boolean = null,
        orderDefinition: string[] = null,
    ): ColumnInfoCollection {

        // Verifica se esiste la definizione della lunghezza delle colonne nel local storage

        let widthDefinition = null;
        let gridUserLayout = this._columnsProvider.userLayoutMetaData?.grids?.length > 0 ?
        this._columnsProvider.userLayoutMetaData.grids.find((g) => {
            const fullPathNameCamelCase = g.fullPathName
                .split('.')
                .map((f) => MetaDataUtils.toCamelCase(f))
                .join('.')

            return fullPathNameCamelCase === this.masterDetailGridName;
        })
        : null

        if (gridUserLayout?.customizedColumns?.length > 0) {
            const gridUserLayoutFullPathNameCamelCase = gridUserLayout.fullPathName
                .split('.')
                .map((f) => MetaDataUtils.toCamelCase(f))
                .join('.')

            widthDefinition = gridUserLayout?.customizedColumns.map((c) => {
                let temp = c.fullPathName
                    .split('.')
                    .map((f) => MetaDataUtils.toCamelCase(f))
                    .join('.');

                if (temp.startsWith(gridUserLayoutFullPathNameCamelCase)) {
                    temp = temp.substring(gridUserLayoutFullPathNameCamelCase.length + 1);
                }

                const fullPropertyName = temp.replace('selectedItem.', '');
                const isAutoSize = c.isAutoSize;
                const width = c.width;
                return {
                    fullPropertyName, isAutoSize, width
                }
            });
        }

        return ColumnsUtils.getGridColumns(
            this._columnsProvider,
            entityTypeName,
            visibilityDefinition,
            editableDefinition,
            orderDefinition,
            widthDefinition
        );
    }

    async execSearch(value: string, forceSearch = false, options?: AutoCompleteModelOptions) {
        if (this.searchIsValid(value) || forceSearch) {
            if (this.extendedFilter.relationViewModels.size > 0 ) {
                for (const [key, relationViewModel] of this.extendedFilter.relationViewModels) {
                    if (relationViewModel instanceof ExternalViewModel && relationViewModel.hasDecodeError === true) {
                        this._orchestratorViewModel.toastMessageService.showToast({
                            type: ToastMessageType.warn,
                            title: "Attenzione",
                            message: `Filtro ${relationViewModel.metadataShortDescription} non valido!`
                        })
                        return;
                    }
                }
            }

            this._searchInProgress = true;
            this.actionInProgress$.next(true);
            this.searchResult.setMaxPagination(40);
            this.searchResult.getPaginatedCollectionItems = async (take: number, skip: number) => {
                return await this.retrieveDomainModelsByValueAsync(value, take, skip, options);
            };
            this.searchResult.datasourceUpdated.emit();
        }
    }

    setAutoCompleteFilter(path: string, value: any) {
        const ovm = this.externalRetriever as MasterDetailOrchestratorViewModelInterface;
        let autoCompleteOptions = ovm.getAutoCompleteModelOptions();

        if (!ovm.setCustomAutoCompleteFilterForPath(path, value, autoCompleteOptions)) {
            const pascalCasePath = path
                .split('.')
                // TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
                .map((splitted) => MetaDataUtils.toPascalCase(splitted))
                .join('.');

            const isEnum =  this.extendedFilter?.getProperty(path) instanceof BaseEnumPropertyViewModel
            if (value == null || value.length === 0 || (value === 0 && !isEnum)) {
                const filterIndex = autoCompleteOptions.additionalFilters.findIndex(af => af.name === pascalCasePath);
                if (filterIndex > -1) {
                    autoCompleteOptions.additionalFilters.splice(filterIndex, 1);
                }
            } else {
                let filter = autoCompleteOptions.additionalFilters.find(af => af.name === pascalCasePath);
                if (!filter) {
                    filter = new Filter();
                    filter.name = pascalCasePath;
                    autoCompleteOptions.additionalFilters.push(filter);
                }
                filter.value = value;
                filter.applyAndLogic = true;
            }
        }
    }

    //#endregion PUBLIC METHODS

    //#region PROTECTED METHODS
    protected async initMasterElementViewModel(
        domainModel: TModel,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
    ) {

        // Build grid columns
        const gridColumns = new Map<string, ColumnInfoCollection>();
        const columns = ColumnsUtils.getGridColumnsForBaseTypes(domainModel.modelTypeName, aggregateMetadata);

        columns.metadataShortDescription = orchestratorViewModel.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(
            this.aggregateMetaData.rootMetaData.descriptions.displayNameKey) : this.aggregateMetaData.rootMetaData.descriptions.displayName;

        columns.metadataDescription = orchestratorViewModel.useMessageResourceKey ? MessageResourceManager.Current.getMessageIfExists(
            this.aggregateMetaData.rootMetaData.descriptions.descriptionKey) : this.aggregateMetaData.rootMetaData.descriptions.description;

        gridColumns.set(domainModel.modelTypeName, columns);

        this._columnsProvider = new ColumnsProvider(gridColumns, null, orchestratorViewModel.userLayoutMetaData);

        await this._orchestratorViewModel.initMasterAreaCustomGridColumns(this._columnsProvider.customGridColumns);

        await this.initViewModelWithoutModel(aggregateMetadata, orchestratorViewModel, domainModel.modelTypeName);

        // Popolo la search SearchCollectionViewModel
        await this.buildSearchCollectionViewModel();

        // Popolo la search SearchCollectionViewModel
        await this.buildInternalExtendedFilterViewModel(cloneDeep(domainModel), this._orchestratorViewModel.apiClient.rootModelType);
    }

    protected async initViewModelWithoutModel(
        aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        domainModelTypeName: string
    ) {

        if (aggregateMetaData != null) {
            this.aggregateMetaData = aggregateMetaData;
            this.externalRetriever = orchestratorViewModel;
            if (orchestratorViewModel) {
                this.eventDispatcher = orchestratorViewModel.eventDispatcher;
            }

            const domainModelMetaData = aggregateMetaData.domainModels.find(
                (element: DomainModelMetaData) => MetaDataUtils.toCamelCase(element.name) === MetaDataUtils.toCamelCase(domainModelTypeName));
            if (domainModelMetaData != null) {
                this.domainModelMetaData = domainModelMetaData;
                await this.buildPropertyLists();
                this.metadataShortDescription = this.domainModelMetaData.descriptions.displayName;
                this.metadataDescription = this.domainModelMetaData.descriptions.description;
            }

        }
    }

    protected getCollectionViewModelType(propertyName: string): any {
        return CollectionViewModelTypeInspector.getValue(this, propertyName);
    }

    protected async buildInternalExtendedFilterViewModel(domainModel: TModel, domainModelType: ClassConstructor<TModel>) {

        const propertyName = 'extendedFilter';

        const internalViewModelType = RootViewModelTypeInspector.getValue(this._orchestratorViewModel);

        const relationMetadata = new InternalRelationMetaData();
        relationMetadata.principalMetaData.descriptions = this.aggregateMetaData.rootMetaData.descriptions;

        const ivm = await ViewModelFactory.createInternalViewModel(
            internalViewModelType,
            domainModel,
            this.aggregateMetaData,
            this.modifiedSubscriber as CoreOrchestratorViewModelInterface,
            relationMetadata,
            true,
            undefined,
            true,
            undefined,
            domainModelType
        ) as MasterDetailRootViewModel<TModel, TIdentity>;
        this[propertyName] = ivm as any;
        (this[propertyName] as AggregateElementViewModel<TModel, TIdentity>).skipValidation = true;
        this.relationViewModels.set(propertyName, ivm as ViewModelInterface);

        // ivm.viewModelChanged.subscribe(e => {
        //     this._viewModelChangeDebouncer.next();
        // });
    }

    protected async buildSearchCollectionViewModel(models: TModel[] = []) {

        const propertyName = 'searchResult';
        const collection = new SearchResultlCollection<TModel, TIdentity>(null, propertyName, models, false, this._rootDomainModelType);

        // Creo il ViewModel
        const ivm = new MasterViewModelSearchResultCollectionViewModel<TRootViewModel, TModel, TIdentity>();
        ivm.parent = this;
        await ivm.preInit(this._orchestratorViewModel.rootViewModelType);

        const internalCollectionMetaData = new InternalCollectionMetaData();
        internalCollectionMetaData.dependentMetaData = this.aggregateMetaData.rootMetaData;

        await ivm.init(
            collection,
            this.aggregateMetaData,
            this.modifiedSubscriber as OrchestratorViewModelInterface,
            internalCollectionMetaData,
            '',
            true,
            this._orchestratorViewModel.apiClient.rootModelType
        );

        await ivm.postInit();
        this.searchResult = ivm;
        this.relationViewModels.set(propertyName, ivm as BaseViewModelInterface);

    }

    protected searchIsValid(value: string): boolean {
        return value.length >= 3;
    }

    protected async retrieveDomainModelsByValueAsync(value: string, take?: number, skip?: number, options?: AutoCompleteModelOptions): Promise<TModel[]> {
        const ovm = this.externalRetriever as MasterDetailOrchestratorViewModelInterface;
        options = options ?? ovm.getAutoCompleteModelOptions();

        if (this.searchPropertyNames.length === 0) {
            throw new Error('devi impostare searchPropertyNames!');
        }

        options.searchValue = value;
        options.take = take;
        options.skip = skip;
        if(this._orchestratorViewModel.getOrderByPropertyNames() != null && this._orchestratorViewModel.getOrderByPropertyNames().length > 0){
            options.orderByPropertyNames = this._orchestratorViewModel.getOrderByPropertyNames();
        } else if (this._mainProperties.length > 0) {
            options.orderByPropertyNames = this._mainProperties.map((main) => {
                return new OrderBy({
                    // TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
                    propertyName: MetaDataUtils.toPascalCase(main.name),
                    sortType: OrderByType.Ascending
                })
            })
        }

        options.propertySearchList = [];

        this.searchPropertyNames.forEach((propertyPathName: string) => {
            // TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
            options.propertySearchList.push(MetaDataUtils.toPascalCase(propertyPathName));
            // const filter = new Filter();
            // TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
            // filter.name = MetaDataUtils.toPascalCase(propertyPathName);
            // filter.operator = FilterOperators.StartWith;
            // filter.value = value;
            // options.filters.push(filter);
        });

        return await ovm.autoComplete(options) as TModel[];
    }
    //#endregion PROTECTED METHODS
}
