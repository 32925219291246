import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, ChangeDetectorRef, HostListener, Injector, AfterContentInit, ChangeDetectionStrategy, ComponentFactory, ComponentRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrchestratorViewModelInterface } from '../../view-models/orchestrator-view-model.interface';
import { MasterViewModelInterface } from '../../view-models/master-view-model.interface';
import { MasterDetailOrchestratorViewModelInterface } from '../../view-models/master-detail-orchestrator-view-model.interface';
import { ComponentLocator } from '../component-locator';
import { ViewModelStates } from '../../view-models/states/view-model-states';
import { ViewModelLocator } from '../../view-models/view-model-locator';
import { MasterDetailOrchestratorViewModel } from '../../view-models/master-detail-orchestrator-view-model';
import { ViewComponentInterface } from '../view-component.interface';
import { ViewModelInterface } from '../../view-models/view-model.interface';
import { ToolBarViewModelInterface } from '../../view-models/tool-bar-view-model.interface';
import { ToastMessageService } from '../layout/toast-message/toast-message.service';
import { RelatedRootViewModelInterface } from '../../view-models/related-root-view-model.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnapShotAPIClient } from '../../api-clients/snap-shot-api-client';
import { SnapShotListViewModelInterface } from '../../view-models/snap-shot-list-view-model.interface';
import { CurrentRouteService, RouteChangeParams, RoutingService } from '../../routing';
import { LogService, MessageType, OnlineService, StatusMessageInterface, UpdateService, UpdateServiceData, UpdateServiceStatusType, UUIDHelper } from '@nts/std/utility';
import { TokenService } from '../../auth/token.service';
import { BaseContainerComponent } from '../layout/base-container/base-container.component';
import { ActionInProgressInterface } from '../../view-models/view-model-event-dispatcher';
import { ServiceResponse } from '../../responses/service-response';
import { BaseError } from '../../messages/base-error';
import { ComponentErrorInterface } from '../errors/component-error.interface';
import { ForbiddenComponent } from '../errors/forbidden/forbidden.component';
import { SpoolProcess } from '../../domain-models/spool/spool-process';
import { HttpEvent, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, filter, take } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { ToastMessageType } from '../layout/toast-message/toast-message';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { UIStarter } from '../../starter/ui-starter';
import { PresentationCache } from '../../cache/presentation-cache';
import { AsyncPipe, Location, NgClass, NgIf } from '@angular/common';
import { OperationIdentity } from '../../domain-models/report/operation.identity';
import { ReportInfoDto } from '../../domain-models/report/report-info.dto';
import { AuthService } from '../../auth/auth.service';
import { MetaDataResponse } from '../../responses/meta-data-response';
import { DynamicPresentationComponent } from '../dynamic-presentation/dynamic-presentation.component';
import { CoreToolBarViewModelInterface } from '../../view-models/core-tool-bar-view-model.interface';
import { LayoutDefinitionIdentity } from '../../domain-models/layout/layout-definition.identity';
import { BaseAggregateComponent } from '../base-aggregate/base-aggregate.component';
import { ClassConstructor } from '@nts/std/serialization';
import { BaseLayoutDataDto } from '../../domain-models/layout/base-layout-data.dto';
import { MsgClearMode } from '../../view-models/core-orchestrator-view-model';
import { animate, sequence, state, style, transition, trigger } from '@angular/animations';
import { LongOpOrchestratorViewModel } from '../../view-models/long-op/long-op-orchestrator-view-model';
import { OrchestratorViewModel } from '../../view-models/orchestrator-view-model';
import { LongOpOrchestratorViewModelInterface } from '../../view-models/long-op/long-op-orchestrator-view-model.interface';
import { GenericServiceResponse } from '../../responses/generic-service-response';
import { AvailableLayoutsInfoDto } from '../../domain-models/layout/available-layouts-info.dto';
import { TelemetryService } from '@nts/std/telemetry';
import { LoaderComponent } from '../shared/loader/loader.component';
import { PageHeaderComponent } from '../layout/page-header/page-header.component';
import { TextButtonComponent } from '../shared/buttons/text-button/text-button.component';
import { NavigationPanelComponent } from '../navigation-panel/navigation-panel.component';
import { ToolBarComponent } from '../layout/tool-bar/tool-bar.component';
import { ValidationComponent } from '../layout/validation/validation.component';
import { NotificationComponent } from '../layout/notification/notification.component';
import { WingContainerComponent } from '../wing-container/wing-container.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MenuItem } from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'nts-model-container',
  templateUrl: './model-container.component.html',
  styleUrls: ['./model-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sideBarLeftOpenClose', [
      state('open', style({
        opacity: 1,
        display: 'flex',
        flexBasis: '280px',
      })),
      state('fullScreen', style({
        opacity: 1,
        display: 'flex',
        flexBasis: '100%',
      })),
      state('closed', style({
        opacity: 0,
        display: 'none',
        flexBasis: '0px',
      })),

      transition('open => closed', [
        sequence([
          animate('0.2s', style({
            opacity: '0',
            flexBasis: '280px'
          })),
          style({ display: 'none' }),
          animate('0.2s ease', style({
            flexBasis: '0'
          })),
        ])
      ]),
      transition('closed => open', [
        style({ display: 'flex' }),
        animate('0.2s 0.2s ease', style({
          flexBasis: '280px',
          opacity: '0',
        })),
        animate('0.2s', style({
          opacity: '1'
        })),
      ]),
    ]),
    trigger('sideBarLeftToggleVisibility', [
      state('visible', style({
        left: '9px',
        opacity: '1'
      })),
      state('hidden', style({
        left: '-40px',
        opacity: '0'
      })),
      transition('hidden => visible', [
        sequence([
          animate('0.2s 0.4s ease')
        ])
      ]),
      transition('visible => hidden', [
        sequence([
          animate('0.2s ease')
        ])
      ]),
    ])
  ],
  standalone: true,
  imports: [
    LoaderComponent,
    NgClass,
    PageHeaderComponent,
    NgIf,
    TextButtonComponent,
    AsyncPipe,
    NavigationPanelComponent,
    ToolBarComponent,
    ValidationComponent,
    NotificationComponent,
    WingContainerComponent,
    SvgIconComponent
  ]
})
export class ModelContainerComponent<TOrchestratorViewModel extends LongOpOrchestratorViewModelInterface | OrchestratorViewModelInterface = LongOpOrchestratorViewModelInterface | OrchestratorViewModelInterface> extends BaseContainerComponent implements OnInit, AfterContentInit {

  @ViewChild('documentPlaceHolder', { read: ViewContainerRef, static: true }) documentPlaceHolder: ViewContainerRef;
  @ViewChild(ToolBarComponent, { static: false }) toolBar: ToolBarComponent;

  loaderText: string = '';
  loaderAnimation: boolean = false;
  viewModelLoaded: boolean = false;
  updateStatus: UpdateServiceData = {event: null, status: UpdateServiceStatusType.NoUpdateAvailable};
  moreOptionsMenuItemList$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  mobileMenuItemList$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);


  private _domainModelName: string = '';
  private _hasExternalReturn: boolean = false;
  private _hasHistoryBackButton: boolean = false;
  private _isRelated: boolean = false;
  private _currentLayout: string|null = null;
  private _isMasterDetail: boolean = false;
  private _isLongOp: boolean = false;
  private _isCrud: boolean = false;
  private _hasSnapShotFeature: boolean = false;
  protected internalOrchestratorViewModel!: TOrchestratorViewModel;

  currentStandardComponentType: ClassConstructor<BaseAggregateComponent<any>> = null;
  currentStandardComponentInstance: ViewComponentInterface<ViewModelInterface> = null;

  get orchestratorViewModel(): TOrchestratorViewModel {
    return this.internalOrchestratorViewModel;
  }

  get masterViewModel(): MasterViewModelInterface|null {
    if (this.isMasterDetail) {
      const orchestratorViewModel: MasterDetailOrchestratorViewModelInterface = this.internalOrchestratorViewModel as MasterDetailOrchestratorViewModelInterface;
      return (orchestratorViewModel && 
        orchestratorViewModel.masterViewModel) ? orchestratorViewModel.masterViewModel : null;
    }
    return null;
  }

  get snapShotListViewModel(): SnapShotListViewModelInterface|null {
    if (this._hasSnapShotFeature) {
      const orchestratorViewModel: OrchestratorViewModelInterface = this.internalOrchestratorViewModel as OrchestratorViewModelInterface;
      return orchestratorViewModel && orchestratorViewModel.snapShotListViewModel ? orchestratorViewModel.snapShotListViewModel : null;
    }
    return null;
  }

  get isMasterDetail() {
    return this._isMasterDetail;
  }

  get hasSnapShotFeature() {
    return this._hasSnapShotFeature;
  }

  constructor(
    protected override readonly routingService: RoutingService,
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly tokenService: TokenService,
    protected override readonly toastMessageService: ToastMessageService,
    protected override readonly updateService: UpdateService,
    protected override readonly onlineService: OnlineService,
    protected override readonly authService: AuthService,
    protected override readonly telemetryService: TelemetryService,
    protected override readonly environmentConfiguration: EnvironmentConfiguration,
    protected override readonly cd: ChangeDetectorRef,
    protected override readonly componentFactoryResolver: ComponentFactoryResolver,
    protected override readonly currentRouteService: CurrentRouteService,
    protected readonly router: Router,
    protected readonly componentLocator: ComponentLocator,    
    protected readonly location: Location,
  ) {
    super(
      routingService, 
      activatedRoute, 
      tokenService, 
      toastMessageService, 
      updateService, 
      onlineService, 
      authService,
      telemetryService,
      environmentConfiguration,
      cd,
      componentFactoryResolver,
      currentRouteService
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.activatedRoute.parent.snapshot.queryParams['related'] != null) {
      this._isRelated = this.activatedRoute.parent.snapshot.queryParams['related'] === 'true';
    }
    if (this.activatedRoute.parent.snapshot.queryParams['layout'] != null) {
      this._currentLayout = this.activatedRoute.parent.snapshot.queryParams['layout'];
    }
    if (this.activatedRoute.snapshot.queryParams['external-return'] != null) {
      this._hasExternalReturn = this.activatedRoute.snapshot.queryParams['external-return'] === 'true';
    }
    if (this.activatedRoute.snapshot.queryParams['history-back'] != null) {
      this._hasHistoryBackButton = this.activatedRoute.snapshot.queryParams['history-back'] === 'true';
    }

    this._domainModelName = this.activatedRoute.snapshot.data['domainModelName'] ?
      this.activatedRoute.snapshot.data['domainModelName'].toLowerCase() : this.router.url.split('/')[2].toLowerCase().split('?')[0];

    const orchestratorViewModel = this.getOrchestrator(this._domainModelName) as TOrchestratorViewModel;

    this.internalOrchestratorViewModel = orchestratorViewModel;
    this._isMasterDetail = orchestratorViewModel instanceof MasterDetailOrchestratorViewModel;
    this._isLongOp = orchestratorViewModel instanceof LongOpOrchestratorViewModel;
    this._isCrud = orchestratorViewModel instanceof OrchestratorViewModel;

    if (!this.internalOrchestratorViewModel) { throw new Error('Missing orchestratorViewModel!'); }

    this.updateQueryParams();
    this.handleExternalReturnLogic();
    this.handleIsRelatedLogic();
    this.handleHasLayoutLogic();
    this.handleHasHistoryBackButtonLogic();
    this.startSubscribers();

  }

  showUpdateNotificationToast(updateServiceData: UpdateServiceData) {
    switch (updateServiceData?.status) {
      case UpdateServiceStatusType.Updating:
        this.toastMessageService.showToast({
          title: 'Aggiornamento in corso',
          message: 'E\' disponibile una nuova versione. Sto scaricando la nuova versione...',
          type: ToastMessageType.info
        })
        break;
      case UpdateServiceStatusType.UpdatedNeedReload:
          this.toastMessageService.showUpdateConfirmToast({
            title: 'Aggiornamento disponibile',
            message: 'E\' disponibile una nuova versione. Clicca sul pulsante Aggiorna per aggiornare la pagina.',
            type: ToastMessageType.info
          }, 'Aggiorna', 'refresh').pipe(this.untilDestroyed()).subscribe(_ => window.location.reload());
        break;
      case UpdateServiceStatusType.UpdateError:
        this.toastMessageService.showUpdateConfirmToast({
          title: 'Aggiornamento fallito',
          message: 'L\'aggiornamento alla nuova versione non è andato a buon fine. Clicca sul pulsante Aggiorna per aggiornare la pagina e riprovare.',
          type: ToastMessageType.warn
        }, 'Aggiorna', 'refresh').pipe(this.untilDestroyed()).subscribe(_ => window.location.reload());
        break;
    }
  }

  protected override async handleUpdateNotification(evt) {
    // Disable update toast notification in standard container
    // We use notification icon in toolbar
    switch (evt.type) {
      case 'VERSION_DETECTED':
        LogService.debug(`Downloading new app version: ${evt.version.hash}`);
        this.updateStatus = {
          event: evt,
          status: UpdateServiceStatusType.Updating
        };
        this.cd.detectChanges();
        break;
      case 'VERSION_READY':
        LogService.debug(`Current app version: ${evt.currentVersion.hash}`);
        LogService.debug(`New app version ready for use: ${evt.latestVersion.hash}`);
        setTimeout(() => {
          this.updateStatus = {
            event: evt,
            status: UpdateServiceStatusType.UpdatedNeedReload
          };
          this.cd.detectChanges();
        });
        break;
      case 'VERSION_INSTALLATION_FAILED':
        LogService.debug(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        this.updateStatus = {
          event: evt,
          status: UpdateServiceStatusType.UpdateError
        };
        this.cd.detectChanges();
        break;
    }
  }

  handleHasHistoryBackButtonLogic() {
    if (this._hasHistoryBackButton) {
      (this.internalOrchestratorViewModel.toolBarViewModel as ToolBarViewModelInterface).addHistoryBackButtonCommand(this.routingService);
    }
  }

  handleHasLayoutLogic() {
    if (this._currentLayout) {
      this.internalOrchestratorViewModel.setStartingLayoutCode(this._currentLayout);
    }
  }

  handleIsRelatedLogic() {
    if (this._isRelated) {
      if (this.internalOrchestratorViewModel.setStartedAsRelatedClient !== undefined) {
        this.internalOrchestratorViewModel.setStartedAsRelatedClient();
      }
    }
  }

  handleExternalReturnLogic() {
    if (this._hasExternalReturn) {
      // se sono in un iframe non esiste windows openere ma windows parent
      const commandMessageAction: (jsonIdentity: string) => void = (jsonIdentity: string) => {
        this.tabMessagesSocket.send(jsonIdentity, MessageType.ExternalReturn);
      };

      (this.internalOrchestratorViewModel.toolBarViewModel as CoreToolBarViewModelInterface).addExternalReturnCommand('', commandMessageAction);
    }
  }

  handleToolBarSubscribers() {

    this.orchestratorViewModel.toolBarViewModel.loadedCompleted.pipe(filter((completed) => completed)).subscribe(async () => {
      
      this.moreOptionsMenuItemList$.next([
        ...await this.orchestratorViewModel.toolBarViewModel.getMoreOptionsMenuItems(this.toolBar?.moreOptionsMenu)
      ])

      this.mobileMenuItemList$.next([
        ...await this.orchestratorViewModel.toolBarViewModel.getMobileMenuItems(this.toolBar?.mobileMenu)
      ])
    })

    this.orchestratorViewModel.moreOptionsMenuItemUpdated.pipe(untilDestroyed(this)).subscribe(async () => {
      this.moreOptionsMenuItemList$.next([
        ...await this.orchestratorViewModel.toolBarViewModel.getMoreOptionsMenuItems(this.toolBar?.moreOptionsMenu)
      ])
    });

    this.orchestratorViewModel.mobileMenuItemUpdated.pipe(untilDestroyed(this)).subscribe(async () => {
      this.mobileMenuItemList$.next([
        ...await this.orchestratorViewModel.toolBarViewModel.getMobileMenuItems(this.toolBar?.mobileMenu)
      ])
    });
  }

  startSubscribers() {
    this.internalOrchestratorViewModel.eventDispatcher.onActionInProgress.pipe(untilDestroyed(this)).subscribe((actionInProgress: boolean | ActionInProgressInterface) => {
      if ((actionInProgress as ActionInProgressInterface).inProgress === true || (actionInProgress as ActionInProgressInterface).inProgress === false) {
        this.loaderText = (actionInProgress as ActionInProgressInterface).loaderText ?? '';
        this.loaderAnimation = (actionInProgress as ActionInProgressInterface).loaderAnimation ?? false;
      } else {
        this.loaderText = '';
        this.loaderAnimation = false;
      }
      this.cd.detectChanges();
    });

    this.internalOrchestratorViewModel.eventDispatcher.onNavigationPanelCollapsed.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    })

    this.internalOrchestratorViewModel.spoolProcessCompleted.pipe(untilDestroyed(this)).subscribe(async (process: SpoolProcess<any>) => {
      this.cd.detectChanges();
      await this.orchestratorViewModel.executeSpoolProcessCompleted(process);
    })

    this.internalOrchestratorViewModel.openSpoolProcessRequested.pipe(untilDestroyed(this)).subscribe(async (operationIdentity: OperationIdentity) => {
      const routeChangeParams: RouteChangeParams = new RouteChangeParams();
      routeChangeParams.inBlank = true;
      routeChangeParams.addCredentials = true;
      routeChangeParams.rootModelFullName = 'SpoolService.OperationObjects.Models.Operation';
      routeChangeParams.routeParam = RoutingService.encodeObject(operationIdentity);
      this.routingService.routeChangeRequested.next(routeChangeParams)
    })

    this.internalOrchestratorViewModel.changeLayoutRequested.pipe(untilDestroyed(this)).subscribe(async (identity: LayoutDefinitionIdentity) => {

      // Verifico se ho già un query string
      const url: string = window.location.href;
      const splittedUrl: string[] = url.split('?');
      let calculatedUrl: string;
      if (splittedUrl[1] != null) { // Ho già un query string

        // https://armno.medium.com/creating-a-url-with-httpparams-in-angular-42783205f3f4
        const httpParams: HttpParams =
          new HttpParams({ fromString: splittedUrl[1] })  // Attenzione HttpParams è immutable
            .set('layout', identity.code);

        calculatedUrl = splittedUrl[0] + '?' + httpParams.toString();
      } else { // Non ho un query string
        calculatedUrl = url + '?layout=' + identity.code;
      }
      window.location.href = calculatedUrl;
    })

    this.internalOrchestratorViewModel.resetLayoutRequested.pipe(untilDestroyed(this)).subscribe(async (identity: LayoutDefinitionIdentity) => {
      const dto: BaseLayoutDataDto = new BaseLayoutDataDto();
      if (identity.code !== 'standard') {
        dto.layoutIdentity = identity;
      }
      this.internalOrchestratorViewModel.eventDispatcher.onActionInProgress.next(true);
      const response: GenericServiceResponse<Boolean> = await firstValueFrom(this.internalOrchestratorViewModel.apiClient.clearUserLayoutMetaDataAsync(dto));
      if (response.operationSuccedeed === true) {
        window.location.reload();
      } else {
        this.internalOrchestratorViewModel.eventDispatcher.onActionInProgress.next(false);
        this.internalOrchestratorViewModel.showFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
      }
    })

    this.internalOrchestratorViewModel.openLongOpReportRequested.pipe(untilDestroyed(this)).subscribe(async (reportInfoDto: ReportInfoDto) => {
      const routeChangeParams: RouteChangeParams = new RouteChangeParams();
      routeChangeParams.inBlank = true;
      routeChangeParams.fullUrl = reportInfoDto.uiFullAddress;
      routeChangeParams.addCredentials = true;
      routeChangeParams.rootModelFullName = reportInfoDto.ownerObjectFullName;
      this.routingService.routeChangeRequested.next(routeChangeParams);
    })
  }

  updateQueryParams() {
    this.internalOrchestratorViewModel.queryParams = this.activatedRoute.parent.snapshot.queryParams
  }

  getOrchestrator(domainModelName: string): OrchestratorViewModelInterface {
    const orchestratorViewModelType = ViewModelLocator.getOrchestratorViewModelType(domainModelName);

    // Composition e DI dinamica
    // NB Devono essere riesplicitati tutti i provider della catena di DI
    // in quanto non vengono considerati quelli globali

    const injector: Injector = Injector.create({
      providers: ViewModelLocator.getOrchestratorViewModelProviders(orchestratorViewModelType)
    });

    const ovm: OrchestratorViewModelInterface = injector.get<OrchestratorViewModelInterface>(orchestratorViewModelType);
    ovm.isDetached$ = this.isDetached$
    return ovm;
  }

  onToolBarReady() {
    this.handleToolBarSubscribers();
  }

  async ngAfterContentInit() {

    this.documentPlaceHolder.clear();

    // Visualizzo il loader
    this.internalOrchestratorViewModel.eventDispatcher.onActionInProgress.next({
      inProgress: true,
      loaderText: MessageResourceManager.Current.getMessage('std_CaricamentoInProgress')
    });

    // Aggiungendo questa logica si aspetta l'eventuale redirect senza far vedere messaggi di errore
    if(!await this.handleTokenIdParam()) {
      this.internalOrchestratorViewModel.eventDispatcher.onActionInProgress.next({
        inProgress: true,
        loaderText: 'Cambio tenant in corso...'
      });
      return;
    }

    if (this.currentRouteService.isAuthenticatedRoute) {
      let error: BaseError;
      if (this.internalOrchestratorViewModel.getCustomEnterpriseDataError) {
        error = await this.internalOrchestratorViewModel.getCustomEnterpriseDataError();
      } else {
        error = await this.internalOrchestratorViewModel.authService.getEnterpriseDataError();
      }
      
      if (error) {
        return this.renderErrorComponentFromErrors([error]);
      }
    }    

    // Pre init ovm
    if (this.internalOrchestratorViewModel.preInit) {
      await this.internalOrchestratorViewModel.preInit();
    }    

    // Composition e DI dinamica
    const viewModelType: any = this.internalOrchestratorViewModel.rootViewModelType;
    let componentType: ClassConstructor<BaseAggregateComponent<any>>;

    // const isOnline = window.navigator.onLine;
    // if (!isOnline) {
    //   const offlineModeError = new BaseError();
    //   offlineModeError.code = 'OfflineMode';
    //   offlineModeError.description = 'Sei offline!';
    //   return this.renderErrorComponentFromError(offlineModeError)
    // }

    // Verifico se l'utente ha i permessi per avere lo snapshot
    if (this.internalOrchestratorViewModel.apiClient instanceof SnapShotAPIClient) {
      this.internalOrchestratorViewModel.canAccessToSnapShot().pipe(this.untilDestroyed() ,take(1)).subscribe((res) => {
        this._hasSnapShotFeature = res;
        this.cd.detectChanges();
      })
    }

    // Verifico se l'utente ha i permessi per gestire il layout
    const canAccessToLayout: boolean = await firstValueFrom(this.internalOrchestratorViewModel.canAccessToLayout().pipe(this.untilDestroyed()));

    if (canAccessToLayout) {
      // Inizializzo la logica per i layout della maschera
      const response: GenericServiceResponse<AvailableLayoutsInfoDto> = await this.internalOrchestratorViewModel.initLayoutsMetaData();
      if (!response.operationSuccedeed) {
        return await this.renderErrorComponentFromResponse(response);
      }

      // Se ho un selezionato un template personalizzato
      if (this.internalOrchestratorViewModel.layoutMetaData) {
        this.currentStandardComponentType = DynamicPresentationComponent;
      } else {
        this.currentStandardComponentType = this.componentLocator.getComponentType(viewModelType);
      }
    } else {
      this.currentStandardComponentType = this.componentLocator.getComponentType(viewModelType);
    }    

    const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(this.currentStandardComponentType);
    const componentRef: ComponentRef<any> = this.documentPlaceHolder.createComponent(componentFactory);
    this.currentStandardComponentInstance = componentRef.instance as ViewComponentInterface<ViewModelInterface>;
    this.currentStandardComponentInstance.orchestratorViewModel = this.internalOrchestratorViewModel;

    let metaDataResponse: MetaDataResponse;

    // Se ho un selezionato un template personalizzato
    if (this.internalOrchestratorViewModel.layoutMetaData) {

      // Imposto il layout identity del layout personalizzato
      this.currentStandardComponentInstance.layoutIdentity = this.internalOrchestratorViewModel.currentLayout.identity;

      // Utilizzo una getMetaData diversa che contiene le informazioni incrociate per i layout personalizzati
      metaDataResponse = await this.internalOrchestratorViewModel.initPresentationMetaData();
      metaDataResponse = await this.internalOrchestratorViewModel.postInitMetaData(metaDataResponse);
    } else {
      metaDataResponse = await this.internalOrchestratorViewModel.initMetaData();
      metaDataResponse = await this.internalOrchestratorViewModel.postInitMetaData(metaDataResponse);
      if (metaDataResponse.operationSuccedeed && metaDataResponse.result) {
        await this.internalOrchestratorViewModel.initUserLayoutMetaData(metaDataResponse.result.rootFullName);
      }
    }

    if (metaDataResponse.operationSuccedeed && metaDataResponse.result) {

      // Inizializza l'orchestrator
      await this.internalOrchestratorViewModel.initialize();      

      // TODO passare i metadati alla toolbar

      let jsonRouteParam: string = null;
      let isSingleAggregate: boolean = false;
      let isCompanySingleAggregate: boolean = false;

      if (this._isLongOp) {

        const ovm: LongOpOrchestratorViewModelInterface = this.internalOrchestratorViewModel as LongOpOrchestratorViewModelInterface;
        jsonRouteParam = ovm.getJsonObjectFromRouteParam(this.activatedRoute.snapshot.params['identityToLookFor']);
        ovm.jsonRouteParam = jsonRouteParam;

      } else if (this._isCrud) {
        const ovm: OrchestratorViewModelInterface = this.internalOrchestratorViewModel as OrchestratorViewModelInterface;
        jsonRouteParam = await ovm.getJsonIdentityFromIdentityParams(this.activatedRoute.snapshot.params['identityToLookFor']);
        ovm.jsonRouteParam = jsonRouteParam;
        isSingleAggregate = ovm.isSingleAggregate();
        isCompanySingleAggregate = ovm.isCompanySingleAggregate();
      }

      // Verifico che non è stato cambiato l'enterprise data nel refresh precedente
      // Nel caso in cui provenissi da un altra ditta/azienda è necessario resettare lo stato dalla getByIdentity alla create per non confondere l'utente con i dati sottostanti
      // Se sono in una single aggregate visto che esiste solo la getByIDentity non devo considerare la logica della create al cambio ditta/azienda
      if (jsonRouteParam != null && (isSingleAggregate === true || isCompanySingleAggregate === true || this.authService.enterpriseDataChanged.value === false)) {
        await this.showRead(jsonRouteParam);
      } else {
        // Reset dello stato del cambio dell'enterprise
        this.authService.enterpriseDataChanged.next(false);
        await this.create();
      }
    } else if (metaDataResponse.operationSuccedeed && !metaDataResponse.result) {
      const error: BaseError = new BaseError();
      error.code = 'InternalServerError'
      error.description = 'I metadati non sono stati trovati.'
      this.renderErrorComponentFromErrors([error]);
    } else {
      return await this.renderErrorComponentFromResponse(metaDataResponse);
    }

    if (this.internalOrchestratorViewModel.actionInProgress) {
      this.internalOrchestratorViewModel.eventDispatcher.onActionInProgress.next(false);
    }    
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    // if (!this.authService.isUserAuthenticated()) {
    //     return true;
    // }
    if (this.unloadHandlerEnabled) {
      return this.internalOrchestratorViewModel.checkIfCanUnload();
    } else {
      return true;
    }  
  }

  async create() {
    if (this._isLongOp || this._isCrud) {
      const ovm: TOrchestratorViewModel = this.internalOrchestratorViewModel as (TOrchestratorViewModel);
      const createResponse: ServiceResponse = await ovm.create();
      if (createResponse.operationSuccedeed === false) {
        return await this.renderErrorComponentFromResponse(createResponse);
      } else {
        this.onViewModelLoaded(this.internalOrchestratorViewModel.rootViewModel, this.currentStandardComponentInstance);
      }
    }
  }

  async renderErrorComponentFromResponse(result: ServiceResponse): Promise<void> {
    
    if (this.orchestratorViewModel.actionInProgress) {
      this.orchestratorViewModel.eventDispatcher.onActionInProgress.next(false);
    } 
    if (result.operationSuccedeed == false) {

      this.documentPlaceHolder.clear();

      if(this.orchestratorViewModel.customRenderErrorComponentFromResponse){
        if (result.errors?.length > 0) {
          result.errors[0].uuid = UUIDHelper.generateUUID();
        }
        
        this.orchestratorViewModel.customRenderErrorComponentFromResponse(result, this.documentPlaceHolder);
        if (result.errors?.length > 0) {
          this.track(result.errors[0]);
        }
        
      } else {

        const defaultError: BaseError = new BaseError();
        defaultError.code = 'DefaultError';
        defaultError.description = 'Errore imprevisto';

        const errors: BaseError[] = result.errors?.length > 0 ? result.errors : [defaultError];


        const componentErrorMapping = this.getComponentErrorMapping();
  
        let componentError: ClassConstructor<ComponentErrorInterface>;
  
        const forbiddenErrors =  errors.filter((e) => 
          e.code === 'Unauthorized' || e.description === 'Forbidden'
        );
  
        if (forbiddenErrors?.length > 0) {
          componentError = ForbiddenComponent;
        } else {
          // se non esiste il componente per gestire il codice di errore
          if (componentErrorMapping[errors[0].code] == null) {
            // aggiungo il codice di default
            errors.unshift(defaultError);
          }
          componentError = componentErrorMapping[errors[0].code];
        }
    
        const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(componentError);
        const componentRef: ComponentRef<any> = this.documentPlaceHolder.createComponent(componentFactory);
        const componentInstance = componentRef.instance as ComponentErrorInterface;

        errors[0].uuid = UUIDHelper.generateUUID();
        componentInstance.errors = errors;
        this.track(errors[0]);
      }

      this.cd.detectChanges();

      LogService.debug('renderErrorComponentFromResponse', result)

    }
  }

  override renderErrorComponentFromErrors(errors: BaseError[]) {

    if (this.orchestratorViewModel.actionInProgress) {
      this.orchestratorViewModel.eventDispatcher.onActionInProgress.next(false);
    } 

    this.documentPlaceHolder.clear();

    if(this.orchestratorViewModel.customRenderErrorComponentFromErrors){
      if (errors?.length > 0) {
        errors[0].uuid = UUIDHelper.generateUUID();
      }        
      this.orchestratorViewModel.customRenderErrorComponentFromErrors(errors, this.documentPlaceHolder);
      if (errors?.length > 0) {
        this.track(errors[0]);
      }        
    } else {

      const defaultError: BaseError = new BaseError();
      defaultError.code = 'DefaultError';
      defaultError.description = 'Errore imprevisto';

      const computedErrors: BaseError[] = errors?.length > 0 ? errors : [defaultError];

      const componentErrorMapping = this.getComponentErrorMapping();

      let componentError: ClassConstructor<ComponentErrorInterface>;

      const forbiddenErrors =  computedErrors.filter((e) => 
        e.code === 'Unauthorized' || e.description === 'Forbidden'
      );

      if (forbiddenErrors?.length > 0) {
        componentError = ForbiddenComponent;
      } else {
        // se non esiste il componente per gestire il codice di errore
        if (componentErrorMapping[computedErrors[0].code] == null) {
          // aggiungo il codice di default
          computedErrors.unshift(defaultError);
        }
        componentError = componentErrorMapping[computedErrors[0].code];
      }
  
      const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(componentError);
      const componentRef: ComponentRef<any> = this.documentPlaceHolder.createComponent(componentFactory);
      const componentInstance = componentRef.instance as ComponentErrorInterface;

      computedErrors[0].uuid = UUIDHelper.generateUUID();
      componentInstance.errors = computedErrors;
      this.track(computedErrors[0]);
    }

    this.cd.detectChanges();

    LogService.debug('renderErrorComponentFromErrors', errors)

  }
  
  override async handlePendingChanges(): Promise<boolean> {
    // se esiste una funzione custom sull'ovm verrà gestita da quella
    if (this.orchestratorViewModel.customHandlingPendingChanges) {
      return this.orchestratorViewModel.customHandlingPendingChanges();
    } else {
      return await this.orchestratorViewModel.checkIfPendingChanges();
    }
  }

  // Gestisce la navigazione senza iframe verso altre pagine o ms
  protected override async handleNavigationOutsideIframe(routeChangeParams: RouteChangeParams) {

    // non è necessario aggiornare l'url quando sono fuori dall'iframe
    if (routeChangeParams.updateOnlyUrl) {
      return;
    }

    // se viene passato fullUrl faccio il redirect direttamente a quel fullUrl
    if (routeChangeParams.fullUrl) {
      if (this.environmentConfiguration.authenticationAppUrl === routeChangeParams.fullUrl) {
        UIStarter.redirectToLogin();
      } else {
        const url = routeChangeParams.addCredentials ? 
          await this.authService.getUrlWithTokens(routeChangeParams.fullUrl) : 
          routeChangeParams.fullUrl;
        window.open(url, routeChangeParams.inBlank ? '_blank' : '_self');
      }
    } else {

      // recupero l'indirizzo del root model full name
      await PresentationCache.addIfNotExist(routeChangeParams.rootModelFullName);
      const returnedUrl: string = PresentationCache.get(routeChangeParams.rootModelFullName);

      // se non è stato trovato faccio redirect a not-found
      if (returnedUrl == null || returnedUrl === '') {
        this.router.navigate(['not-found']);
        return;
      }


      let sameMs: boolean = false;
      // Verifico se sono nello stesso servizio, dal locale
      if (this.environmentConfiguration.baseAppUrl.indexOf('localhost') > -1) {
        // questa verifica la posso fare solo se ho un ovm con rvm e fullname popolato
        if (this.internalOrchestratorViewModel?.rootViewModel?.aggregateMetaData?.rootFullName?.length > 0) {
          const splittedRouteChangeParamsRootModelFullName: string[] = routeChangeParams.rootModelFullName.split('.');
          const splittedOrchestratorViewModelRootModelFullName: string[] = this.internalOrchestratorViewModel.rootViewModel.aggregateMetaData.rootFullName.split('.');
          // Verifico se sono nello stesso ms dalla prima parte del namespace
          sameMs = splittedRouteChangeParamsRootModelFullName[0] === splittedOrchestratorViewModelRootModelFullName[0];
        }
      } else {
        // Se non sono in locale verifico l'url della finduiinfo con quello del mio env
        sameMs = returnedUrl.indexOf(this.environmentConfiguration.baseAppUrl) > -1;
      }

      if (sameMs) {

        const splittedUrl: string[] = returnedUrl.split('/manage');

        if (routeChangeParams.inBlank) {

          const url: string = this.environmentConfiguration.baseAppUrl + '/manage/' + splittedUrl[1] + ((routeChangeParams.routeParam?.length > 0) ? '/' + routeChangeParams.routeParam : '');

          window.open(
            routeChangeParams.addCredentials ? 
              await this.authService.getUrlWithTokens(url) : url,
            '_blank'
          );
        } else {
          this.router.navigate(['manage/' + splittedUrl[1] + (routeChangeParams.routeParam?.length > 0) ? '/' + routeChangeParams.routeParam : ''])
        }

      } else {
        // se devo andare in un ms diverso
        window.open(
          returnedUrl + ((routeChangeParams.routeParam?.length > 0) ? '/' + routeChangeParams.routeParam : ''),
          '_blank'
        )
      }
    }
  }

  protected untilDestroyed() {
    return untilDestroyed(this);
  }

  private onViewModelLoaded(vm: ViewModelInterface, componentInstance: ViewComponentInterface<ViewModelInterface>) {
    if (vm != null) {
      this.viewModelLoaded = true;
      this.cd.detectChanges();
      componentInstance.onViewModelLoaded();
    } else {
      const error: BaseError = new BaseError();
      error.code = 'InternalServerError'
      error.description = 'RootViewModel NULLO'
      this.renderErrorComponentFromErrors([error]);
    }
  }

  private async showRead(jsonObjectParam: string): Promise<void> {

    let ovm: OrchestratorViewModelInterface | LongOpOrchestratorViewModelInterface = null;
    let error = false;
    if (this._isCrud) {
      ovm = this.internalOrchestratorViewModel as OrchestratorViewModelInterface;
      if (ovm.getByJsonIdentity != null) {

        let result: ServiceResponse;
        try {
          if (this._isRelated) {
            // Effettiamo la conversione dell'identity prima della orchestratorViewModel.create per dare la possibilità
            // allo sviluppatore di avere l'identity a disposizione prima della creazione
            const identity = ovm.convertJsonIdentity(jsonObjectParam);
            if (identity != null) {
              result = await ovm.getByJsonIdentity(jsonObjectParam);

              if (!result.operationSuccedeed){
                error = true;
                await this.renderErrorComponentFromResponse(result);
              } else {
                const relatedRootViewModel: RelatedRootViewModelInterface = ovm.typedRootViewModel as RelatedRootViewModelInterface;
                if (relatedRootViewModel === undefined) {
                  throw new Error('RelatedViewModel not defined');
                }
  
                if (relatedRootViewModel.postInitForStartedAsRelatedClient != null) {
                  await relatedRootViewModel.postInitForStartedAsRelatedClient(
                    this.orchestratorViewModel.currentState.value === ViewModelStates.New,
                    identity
                  );
                }
              }  
            }
          } else {
            result = await ovm.getByJsonIdentity(jsonObjectParam);
            if (!result.operationSuccedeed){
              error = true;
              await this.renderErrorComponentFromResponse(result);
            }
          }
          
        } catch (catchedError) {
          LogService.error('showRead CRUD error', catchedError);
          const err: BaseError = new BaseError();
          const message = catchedError.message ? catchedError.message : catchedError.toString();
          err.code = 'InternalServerError'
          err.description = message;
          err.stackTrace = catchedError.stack
          error = true;
          this.renderErrorComponentFromErrors([err]);
        }
      }
    } else if (this._isLongOp) {
      ovm = this.internalOrchestratorViewModel as LongOpOrchestratorViewModelInterface;
      if (ovm.getByJsonObject != null) {
        if (!ovm.actionInProgress) {
          ovm.eventDispatcher.onActionInProgress.next(true);
        }        

        let result: ServiceResponse;
        try {
          result = await ovm.getByJsonObject(jsonObjectParam);
          if (!result.operationSuccedeed){
            error = true;
            await this.renderErrorComponentFromResponse(result);
          }
        } catch (error) {
          LogService.error('showRead LongOp error', error);        
          const err: BaseError = new BaseError();
          const message = error.message ? error.message : error.toString();
          err.code = 'InternalServerError'
          err.description = message;
          err.stackTrace = error.stack
          error = true;
          this.renderErrorComponentFromErrors([error]);
        }
      }
    }
    if (!error){
      this.onViewModelLoaded(ovm.rootViewModel as ViewModelInterface, this.currentStandardComponentInstance);
    }
  }

  //la logica di verifica degli errori dell'enterprise viene già gestita nella ngAfterContentInit
  override async checkEnterpriseError(): Promise<boolean> {
    return false;
  }

  override async checkStatus(): Promise<StatusMessageInterface> {
    // se esiste una funzione custom sull'ovm verrà gestita da quella
    if (this.orchestratorViewModel.customHandlingCheckStatus) {
      return this.orchestratorViewModel.customHandlingCheckStatus();
    } else {
      return await this.orchestratorViewModel.checkStatus();
    }
  }
}
