<ng-container *ngIf="collectionViewModel?.isVisible">
  <div class="toolbar-section" *ngIf="collectionViewModel && showToolbar">
    <div class="toolbar-control-content">
      <div class="horizontal-container">
        <div *ngIf="isSecurityAccessReadOnly()" class="locked-field">
          <svg-icon key="locked"
            [popper]="securityTooltipDescription"
            [popperDisabled]="!securityTooltipDescription || securityTooltipDescription?.length == 0"
            [popperTrigger]="ngxPopperjsTriggers.hover"
            [popperDelay]="1000"
            [popperApplyClass]="securityTooltipClass"
            [popperPlacement]="ngxPopperjsPlacements.TOP"
            [popperPreventOverflow]="false"
            [popperHideOnScroll]="true"
            popperAppendTo="body">
          </svg-icon>
        </div>
        <div *ngIf="title?.length > 0" class="caption-control" role="heading" aria-level="3">{{ title }}</div>
        <div class="root-container">
          <div class="inline-command-bar">
            <div class="action-list-control-container">
              <div class="horizontal-flex-container">
                <div *ngIf="title?.length > 0" class="navigation-bar-row-separator">
                </div>

                <ng-template ngFor let-commandName [ngForOf]="defaultCommandsName.concat(customCommandsName)">
                  <div class="horizontal-flex-container-item-layout"
                    *ngIf="this.collectionViewModel.getCommand(commandName).isVisible$ | async">
                    <div class="command-bar-button-container">
                      <nts-ribbon-button [title]="this.collectionViewModel.getCommand(commandName).tooltip$ | async"
                        [tabIndex]="0" [label]="this.collectionViewModel.getCommand(commandName).displayName$ | async"
                        [icon]="this.collectionViewModel.getCommand(commandName).iconClass$ | async"
                        [isLoading]="this.collectionViewModel.getCommand(commandName).loading$ | async"
                        [isDisabled]="areAllCommandsDisabled || !(this.collectionViewModel.getCommand(commandName).canExecute$ | async) || collectionViewModel?.securityAccess != null"
                        (onClicked)="this.collectionViewModel.getCommand(commandName).execute(this.collectionViewModel.getCommand(commandName), $event)">
                      </nts-ribbon-button>
                    </div>
                  </div>
                </ng-template>

              </div>
            </div>
          </div>
        </div>

        <ng-template *ngIf="!isSecurityAccessDeny()" ngFor let-commandName
          [ngForOf]="defaultViewCommandsName.concat(customViewCommandsName)">
          <div class="command-bar-button-container"
            *ngIf="this.collectionViewModel.getCommand(commandName).isVisible$ | async">
            <nts-ribbon-text-box *ngIf="this.collectionViewModel.getCommand(commandName).isTextbox" [title]="this.collectionViewModel.getCommand(commandName).tooltip$ | async" [placeholder]="this.collectionViewModel.getCommand(commandName).displayName$ | async"  (onInputChange)="this.collectionViewModel.getCommand(commandName).execute(this.collectionViewModel.getCommand(commandName), $event)"></nts-ribbon-text-box>
            <nts-ribbon-button *ngIf="!this.collectionViewModel.getCommand(commandName).isTextbox" [title]="this.collectionViewModel.getCommand(commandName).tooltip$ | async" [tabIndex]="0"
              [icon]="this.collectionViewModel.getCommand(commandName).iconClass$ | async"
              [isLoading]="this.collectionViewModel.getCommand(commandName).loading$ | async"
              [tooltipAppendTo]="tooltipAppendTo"
              [isDisabled]="!(this.collectionViewModel.getCommand(commandName).canExecute$ | async)"
              (onClicked)="this.collectionViewModel.getCommand(commandName).execute(this.collectionViewModel.getCommand(commandName), $event)"></nts-ribbon-button>
          </div>
        </ng-template>

      </div>
    </div>

  </div>
  <div class="grid-section" [class.no-rows]="collectionViewModel?.length === 0 || !collectionViewModel">
    <ng-template *ngIf="isSecurityAccessDeny(); then securityComponent else gridComponent"></ng-template>

    <ng-template #gridComponent>
      <ag-grid-angular
        #popperError="popper"
        [class.has-error]="(collectionViewModel?.errors$ | async)?.length > 0"
        [popper]="tooltipErrTemplate"
        [popperDisabled]="(collectionViewModel?.errors$ | async)?.length === 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="error"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        popperAppendTo="body"
        (keydown.space)="preventScrollWithoutFocus($event)"
        (cellKeyDown)="onCellKeyDown($event)"
        (keydown.shift.enter)="onEnterGrid()"
        (columnResized)="onColumnResized($event)"
        [rowBuffer]="pagination ? 0 : this.minRowNumberHeight * 5 || 34"
        [columnDefs]="columnDefinitions"
        [suppressDragLeaveHidesColumns]="true"
        [components]="components"
        [stopEditingWhenCellsLoseFocus]="false"
        [suppressLoadingOverlay]="true"
        [enableCellTextSelection]="true"
        [headerHeight]="34"
        [rowHeight]="34"
        [gridOptions]="gridOptions"
        [suppressNoRowsOverlay]="true"
        [rowData]="this.pagination ? undefined : collectionViewModel"
        tabindex="0"
        style="width: 100%"
        class="ag-theme-nts">
      </ag-grid-angular>

      <div *ngIf="overlayText" class="overlay-text-container">
	      <div class="overlay-text-description">
		      {{overlayText}}
	      </div>
      </div>
    </ng-template>

    <ng-template #securityComponent>
      <div class="locked-grid">
        <svg-icon key="locked"
          [popper]="securityTooltipDescription"
          [popperDisabled]="!securityTooltipDescription || securityTooltipDescription?.length == 0"
          [popperTrigger]="ngxPopperjsTriggers.hover"
          [popperDelay]="1000"
          [popperApplyClass]="securityTooltipClass"
          [popperPlacement]="ngxPopperjsPlacements.TOP"
          [popperPreventOverflow]="false"
          [popperHideOnScroll]="true"
          popperAppendTo="body">
        </svg-icon>
      </div>
    </ng-template>
    <!-- <nts-grid-columns-sidebar [class.hidden]="!showColumnSettings" [style.max-width.px]="gridSection.clientWidth"
      [style.height.px]="gridSection.clientHeight" [collectionViewModel]="collectionViewModel" [columns]="columns"
      [actionInProgress]="false" [isCollapsed]="!showColumnSettings">
    </nts-grid-columns-sidebar> -->
  </div>
</ng-container>

<popper-content #tooltipErrTemplate>
  <ng-container *ngFor="let item of collectionViewModel?.errors$ | async">
      {{ item }}
  </ng-container>
</popper-content>
