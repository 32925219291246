import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BaseExternalCellRendererComponent } from '../base_external_cell_renderer.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElementRef } from '@angular/core';
import { NgZone } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { EnumPropertyViewModel } from '../../../../../src/lib/view-models/base-type/enum-property-view-model';
import { BoolPropertyViewModel } from '../../../../../src/lib/view-models/base-type/bool-property-view-model';
import { ExternalFieldInputMode } from '../../../../../src/lib/layout-meta-data/external-field-meta-data';
import { MessageResourceManager } from '../../../../../src/lib/resources/message-resource-manager';
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { PopperHelper } from '@nts/std/utility';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MetaDataUtils } from '../../../../../src/lib/meta-data/meta-data-utils';
import { CheckboxComponent } from '../../../../../src/lib/components/shared/checkbox/checkbox.component';
import { PropertyViewModelInterface } from '../../../../../src/lib/view-models/property-view-model.interface';
import { NumericPropertyViewModel } from '../../../../../src/lib/view-models/base-type/numeric-property-view-model';


// create your cellEditor as a Angular component
@UntilDestroy()
@Component({
    selector: 'nts-external-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgxPopperjsModule,
        NgIf,
        CheckboxComponent,
        NgFor,
        AsyncPipe
    ],
    styleUrls: [`./external-cell-renderer.component.scss`],
    template: `
        <span>
            <div *ngIf="!params?.data" class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </span>
        <div *ngIf="params?.data" class="read-template">
            <div class="renderer-cell"
                #popperError="popper"
                [popper]="tooltipErrTemplate"
                popperAppendTo="body"
                popperApplyClass="error"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                [popperDisabled]="(externalPropertyViewModel.errors$ | async).length == 0"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [style.height.px]="cellHeight" [style.width.px]="boolPropertyViewModel ? 32 : null">
                <nts-checkbox *ngIf="boolPropertyViewModel" [(value)]="displayValue" [title]="boolPropertyViewModel.metadataDescription" [isDisabled]="true" ></nts-checkbox>
                <div *ngIf="!boolPropertyViewModel" [class.numeric-alignment]="isNumeric" class="cell-render-content">{{ displayValue }}</div>
            </div>
            <popper-content #tooltipErrTemplate>
                <div *ngFor="let item of externalPropertyViewModel.errors$ | async">
                    {{ item }}
                </div>
            </popper-content>
        </div>
        `
})
export class ExternalCellRendererComponent extends BaseExternalCellRendererComponent implements OnDestroy {

    @ViewChild('popperError', { static: false }) popperError: NgxPopperjsDirective;

    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    displayValue;
    boolPropertyViewModel: BoolPropertyViewModel = null;
    isNumeric = false;

    /**
     * Lista property da visualizzare in Camel Case
     */
    properties = [];

    constructor(
        private readonly cd: ChangeDetectorRef,
        public readonly el: ElementRef,
        private readonly zone: NgZone,
        private readonly renderer: Renderer2) {
        super();
    }

    override agInit(params: any): void {
        super.agInit(params);

        if(this.params.columnInfo.isRef && this.externalPropertyViewModel.decodeProperties && this.externalPropertyViewModel.decodeProperties.length !=0){
            this.properties = this.externalPropertyViewModel.decodeProperties.map((p) => MetaDataUtils.toCamelCase(p))
        } else if (this.params.columnInfo.isRef && this.params.columnInfo.showProperties && this.params.columnInfo.showProperties.length != 0){
            this.properties = this.params.columnInfo.showProperties.map((p) => MetaDataUtils.toCamelCase(p));
        } else if (this.params.columnInfo.isRef){
            this.properties = [this.description.propertyMetaData.name].map((p) => MetaDataUtils.toCamelCase(p));
        } else {
            this.properties = null;
        }

        if (this.params.columnInfo.showCodeInDescription === true) {
            if (this.externalPropertyViewModel?.aggregateMetaData?.rootMetaData?.identityNames?.length > 0) {
                // lo gestisco con una copia del reverse così posso poi pushare dall'ultimo al primo all'inizio delle properties
                for (const property of [...this.externalPropertyViewModel?.aggregateMetaData?.rootMetaData?.identityNames].reverse()) {
                    
                    if (this.properties?.length > 0) {
                        if(!this.properties.includes(MetaDataUtils.toCamelCase(property))) {
                            this.properties.unshift(MetaDataUtils.toCamelCase(property))
                        }
                    } else {
                        this.properties = [ MetaDataUtils.toCamelCase(property)]
                    }
                }
            }
        }

        this.externalPropertyViewModel.externalDomainModelChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.checkDisplayValue();
            this.checkPopper();
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.decodeCompleted.pipe(untilDestroyed(this)).subscribe(() => {
            this.checkDisplayValue();
            this.checkPopper();
            this.cd.detectChanges();
        });
        this.externalPropertyViewModel.onErrorStatusChanged.pipe(untilDestroyed(this)).subscribe(() => {
            this.params.api.refreshCells({ force: true });
            this.checkDisplayValue();
            this.checkPopper();
            this.cd.detectChanges();
        });

        this.externalPropertyViewModel.onFocusRequested.pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            this.el.nativeElement.parentElement.parentElement.scrollIntoView();

            if (this.externalPropertyViewModel.errors$.value?.length > 0) {
                PopperHelper.show(this.popperError);
            }
        });

        this.checkDisplayValue();

        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        // this._tooltip.hide();
    }

    get description(): PropertyViewModelInterface {
        return this.externalPropertyViewModel.descriptionProperties.values().next().value;
    }

    checkDisplayValue() {
        if (!this.params.data) {
            this.displayValue = '';
        } else if (this.properties && this.properties.length != 0) {
            let join = this.params.columnInfo.joinOperator ? this.params.columnInfo.joinOperator : " - ";
            this.params.columnInfo.fieldName.split('.').reduce(
                (o, i, index) => {
                    if (o != undefined && o[i] != undefined && o[i].domainModel != undefined && index == 0) {
                        this.displayValue = "";
                        for (const key in this.properties) {
                            let label = o[i][MetaDataUtils.toCamelCase(this.properties[key])].value;
                            if (typeof label === 'string') {
                                if (label?.length > 0) {
                                    this.displayValue += (this.displayValue?.length > 0 ? join : '') + label;
                                }
                            } else if (typeof label === 'number') {
                                this.displayValue += (this.displayValue?.length > 0 ? join : '') + (label + '');
                            }
                        }
                    }
                },
                this.params.data
            );
        } else {
            if (this.params.columnInfo.propertyTypeName === "ExternalDecode-Enum") {
                this.displayValue = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i, index) => {
                        if (i === 'value' && ((this.params.columnInfo.fieldName.split('.').length - 1) === index)) {
                            const enumPropertyViewModel = o as EnumPropertyViewModel;
                            if (enumPropertyViewModel) {
                                for (let i = 0; i < enumPropertyViewModel.valueDescriptions.length; i++) {
                                    if (enumPropertyViewModel.valueDescriptions[i].key === enumPropertyViewModel.currentItem) {
                                        return enumPropertyViewModel.valueDescriptions[i].description;
                                    }
                                }
                            }
                        } else {
                            return o[i]
                        }
                    },
                    this.params.data
                );
            } else if (this.params.columnInfo.propertyTypeName === "ExternalDecode-Bool") {
                this.boolPropertyViewModel = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i, index) => {
                        if (i === 'value' && ((this.params.columnInfo.fieldName.split('.').length - 1) === index)) {
                            return o;
                        } else {
                            return o[i];
                        }
                    },
                    this.params.data
                );
            } else if (this.params.columnInfo.propertyTypeName === "ExternalDecode-DateTime") {
                this.displayValue = this.params.columnInfo.fieldName.split('.').reduce(
                    (o, i, index) => {
                        if (i === 'value' && ((this.params.columnInfo.fieldName.split('.').length - 1) === index)) {
                            return o.formattedValue;
                        } else {
                            return o[i]
                        }
                    },
                    this.params.data
                );
            } else if (this.params.columnInfo.inputMode === ExternalFieldInputMode.ExternalList) {
                this.displayValue = this.getCodeAndDescriptionForExternalList();
            } else {
                if (this.params.columnInfo.propertyTypeName.endsWith("Numeric")) {
                    this.isNumeric = true;
                }
                this.displayValue = this.params.columnInfo.fieldName.split('.').reduce((o, i) => o[i], this.params.data);
            }
        }
    }

    private getCodeAndDescriptionForExternalList(): string {

        const codePvm = this.externalPropertyViewModel.codeProperties.values().next().value;
        const notDefined = MessageResourceManager.Current.getMessage('std_NotDefined');

        if (this.externalPropertyViewModel.descriptionProperties.length > 0) {
            const descriptionPropertyName = this.externalPropertyViewModel.descriptionProperties[0]?.propertyName;
            const descriptionPvm = this.externalPropertyViewModel?.getProperty(descriptionPropertyName);

            const showCodeInDescription = this.params.columnInfo.showCodeInDescription ?? this.externalPropertyViewModel.showCode;

            if (showCodeInDescription) {
                if (descriptionPvm?.getValue()?.length > 0) {
                    return codePvm.getValue() ? (codePvm.getValue() + ' - ' + descriptionPvm?.getValue()) : notDefined;
                }
            } else {
                if (descriptionPvm?.getValue()?.length > 0) {
                    return codePvm.getValue() ? descriptionPvm?.getValue() : notDefined;
                }
            }
        }

        return codePvm.getValue() ? codePvm?.getValue() : notDefined;
    }

    private checkPopper() {
        if (this.popperError && (this.externalPropertyViewModel.errors$.value == null || this.externalPropertyViewModel.errors$.value.length === 0)) {
            PopperHelper.hide(this.popperError);
        }   
    }
}
