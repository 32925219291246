<ng-template
    *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent">
</ng-template>

<ng-template #baseComponent>
    <nts-base-text-box
        *ngIf="!multiline && !useChips"
        #baseTextBox
        (onBlur)="onBlur($event)"
        (inputChange)="onInput($event)"
        (onKeyDown)="onKeyDown($event)"
        [maskSettings]="this.maskSettings"
        (maskObject)="this.mask = $event"
        [customCommandList]="customCommandList"
        (onValueChange)="modelChange($event)"
        (onFinishEditing)="onFinishEditing.emit($event)"
        [listenClickOutside]="listenClickOutside"
        [maxLength]="propertyViewModel.metadataMaxLength ?? null"
        [tabIndex]="tabIndex"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [errorList]="propertyViewModel?.errors$ | async"
        [isDisabled]="isDisabled || (!softDisable && !(propertyViewModel.isEnabled$ | async))"
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"
        [isReadonly]="isDisabled || (softDisable && !(propertyViewModel.isEnabled$ | async))"
        [placeholder]="placeholder"
        [class.is-multi-language]="isMultiLanguage"
        [attr.data-property-name]="propertyViewModel.propertyName">
    </nts-base-text-box>
    <nts-base-text-area-box
        *ngIf="multiline && !useChips"
        #baseTextBoxArea
        (inputChange)="onInput($event)"
        (onBlur)="onBlur($event)"
        (onValueChange)="modelChange($event)"
        [maxLength]="propertyViewModel.metadataMaxLength ?? null"
        [tabIndex]="tabIndex"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [errorList]="propertyViewModel?.errors$ | async"
        [isDisabled]="isDisabled || (!softDisable && !(propertyViewModel.isEnabled$ | async))"
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [value]="initialValue != null ? initialValue : propertyViewModel.formattedValue$ | async"
        [isReadonly]="isDisabled || (softDisable && !(propertyViewModel.isEnabled$ | async))"
        [placeholder]="placeholder"
        [class.is-multi-language]="isMultiLanguage"
        [attr.data-property-name]="propertyViewModel.propertyName">
    </nts-base-text-area-box>
    <div *ngIf="isMultiLanguage && !useChips"
        [popper]="languageDescription"
        [popperDisabled]="languageDescription?.length === 0"
        [popperTrigger]="ngxPopperjsTriggers.hover"
        popperApplyClass="info"
        [popperDelay]="1000"
        [popperPlacement]="ngxPopperjsPlacements.TOP"
        [popperPreventOverflow]="false"
        [popperHideOnScroll]="true"
        popperAppendTo="body"
        class="multi-language-label" >{{languageCode?.toUpperCase()}}</div>
    <nts-base-chips-box *ngIf="useChips"
        #baseChipsBox
        (onBlur)="onBlur($event)"
        [maskSettings]="this.maskSettings"
        [customCommandList]="customCommandList"
        (onValueChange)="modelChange($event)"
        (onFinishEditing)="onFinishEditing.emit($event)"
        [listenClickOutside]="listenClickOutside"
        [tabIndex]="tabIndex"
        [multiline]="multiline"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [errorList]="propertyViewModel?.errors$ | async"
        [isDisabled]="isDisabled || (!softDisable && !(propertyViewModel.isEnabled$ | async))"
        [defaultBorderColor]="propertyViewModel.defaultColor"
        [activeBorderColor]="propertyViewModel.activeColor"
        [hoverBorderColor]="propertyViewModel.hoverColor"
        [value]="valueForChips"
        [isReadonly]="isDisabled || (softDisable && !(propertyViewModel.isEnabled$ | async))"
        [placeholder]="placeholder"
        [attr.data-property-name]="propertyViewModel.propertyName">
    </nts-base-chips-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box
        #baseSecurityTextBox
        [accessMode]="propertyViewModel.securityAccess"
        [value]="propertyViewModel.formattedValue$ | async">
    </nts-base-security-text-box>
</ng-template>
