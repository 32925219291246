import { Component, ChangeDetectionStrategy, OnInit, OnChanges, OnDestroy, Input, EventEmitter, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { StringPropertyViewModel } from '../../../view-models/base-type/string-property-view-model';
import { BaseNumericPropertyViewModel } from '../../../view-models/base-type/base-numeric-property-view-model';
import { BaseEnumPropertyViewModel } from '../../../view-models/base-type/enum-property-view-model';
import { StringTextBoxComponent } from '../core/string-text-box/string-text-box.component';
import { SearchPropertyViewModelInterface } from '../../../view-models/search-property-view-model.interface';

@Component({
    selector: 'nts-search-text-box',
    templateUrl: './search-text-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./search-text-box.component.scss'],
    standalone: true,
    imports: [
        StringTextBoxComponent
    ]
})
export class SearchTextBoxComponent implements OnInit, OnChanges, OnDestroy {

    @Input() propertyViewModel: SearchPropertyViewModelInterface;
    @Input() tabIndex: number;

    @Output() propertyViewModelInitialized = new EventEmitter<void>();

    searchModel = '';
    isActive = false;

    constructor(protected cd: ChangeDetectorRef) {

    }

    protected propertyChangedSubscription: Subscription = null;

    get isString() {
        return this.propertyViewModel instanceof StringPropertyViewModel;
    }

    get stringPropertyViewModel(): StringPropertyViewModel {
        return this.propertyViewModel as StringPropertyViewModel
    }

    get isNumeric() {
        return this.propertyViewModel instanceof BaseNumericPropertyViewModel;
    }

    get isEnum() {
        return this.propertyViewModel instanceof BaseEnumPropertyViewModel;
    }

    onClear() {
        this.propertyViewModel.resetValue(true);
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    onKeyDown(ev: KeyboardEvent) {
        if (ev.key === 'Enter') {
            this.propertyViewModel.execSearch(this.propertyViewModel.value);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.unsubscribe();

        this.propertyChangedSubscription = this.propertyViewModel.propertyViewModelChanged.subscribe(() => {
            this.cd.detectChanges();
        });

        this.propertyViewModelInitialized.emit();
    }

    unsubscribe() {
        if (this.propertyChangedSubscription != null) {
            this.propertyChangedSubscription.unsubscribe();
        }
    }

    ngOnInit() {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
    }
}
